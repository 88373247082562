import { default as assembly_45linetOmKYqShj9Meta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/assembly-line.vue?macro=true";
import { default as buildCpMGkeCAXVMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/build.vue?macro=true";
import { default as dispatchvb3lwZEhZ5Meta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/dispatch.vue?macro=true";
import { default as assemblySaFcWSN38MMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly.vue?macro=true";
import { default as indexxwI8jysl8TMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/bank/index.vue?macro=true";
import { default as bank9kxkogiVEBMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/bank.vue?macro=true";
import { default as creditsJu2NiS8y1HMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/credits.vue?macro=true";
import { default as eventyEEUGNXRC7Meta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/event.vue?macro=true";
import { default as guig3XqvShMfqMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/gui.vue?macro=true";
import { default as assignmentjoCki7NdquMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/assignment.vue?macro=true";
import { default as _91id_93S1xP6ZGlUiMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/game-pause/recruit/[id].vue?macro=true";
import { default as hired_45staffaYgauZdVBfMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/hired-staff.vue?macro=true";
import { default as recruitmentinJFU50IMoMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/recruitment.vue?macro=true";
import { default as human_45resourcesyaEAmXTuGmMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources.vue?macro=true";
import { default as indexn4bLLTEo2hMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/index.vue?macro=true";
import { default as introPAZEShRNt0Meta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/intro.vue?macro=true";
import { default as load_45gamen2HMcHKXb8Meta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/load-game.vue?macro=true";
import { default as competitors1vh2NpzNxCMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/competitors.vue?macro=true";
import { default as steal50AWpinOcHMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/game-pause/steal.vue?macro=true";
import { default as my_45companyhZsVlBQtRMMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/my-company.vue?macro=true";
import { default as steal_45agreementsyo2ILXc3YzMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/steal-agreements.vue?macro=true";
import { default as market_45studyWbRmEQCJUaMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study.vue?macro=true";
import { default as new_45gameqHjwPvWuVlMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/new-game.vue?macro=true";
import { default as overviewBEwFPBJbQYMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/research/overview.vue?macro=true";
import { default as _91id_93PI1thfzLz2Meta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/research/research-tree/[id].vue?macro=true";
import { default as researchhnLZSXXuHlMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/research.vue?macro=true";
import { default as results0QgZAOswI0Meta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/results.vue?macro=true";
import { default as _91id_93DpP7kRfSpmMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/contract/game-pause/drop/[id].vue?macro=true";
import { default as _91id_93Tw6VfkBGxoMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/contract/game-pause/sign/[id].vue?macro=true";
import { default as marketCm96BoXR2eMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/market.vue?macro=true";
import { default as partnership_45agreementsSfAahs7z4uMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/partnership-agreements.vue?macro=true";
import { default as maprRWPfaiYDbMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/map.vue?macro=true";
import { default as salesIfDIQhfEduMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales.vue?macro=true";
import { default as save_45game2m6GOB9YJoMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/save-game.vue?macro=true";
import { default as _91id_93dibvzyxz9HMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/contract/game-pause/drop/[id].vue?macro=true";
import { default as _91id_93rBmBBdwdIsMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/contract/game-pause/sign/[id].vue?macro=true";
import { default as marketj4DSafke9RMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/market.vue?macro=true";
import { default as material_45storage0irbfwa3JXMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/material-storage.vue?macro=true";
import { default as supply7DqQHEKBbhMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/supply.vue?macro=true";
import { default as warehousev5PYxfjGOxMeta } from "/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse.vue?macro=true";
export default [
  {
    name: assemblySaFcWSN38MMeta?.name ?? "assembly",
    path: assemblySaFcWSN38MMeta?.path ?? "/assembly",
    children: [
  {
    name: assembly_45linetOmKYqShj9Meta?.name ?? "assembly-assembly-line",
    path: assembly_45linetOmKYqShj9Meta?.path ?? "assembly-line",
    meta: assembly_45linetOmKYqShj9Meta || {},
    alias: assembly_45linetOmKYqShj9Meta?.alias || [],
    redirect: assembly_45linetOmKYqShj9Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/assembly-line.vue").then(m => m.default || m)
  },
  {
    name: buildCpMGkeCAXVMeta?.name ?? "assembly-build",
    path: buildCpMGkeCAXVMeta?.path ?? "build",
    meta: buildCpMGkeCAXVMeta || {},
    alias: buildCpMGkeCAXVMeta?.alias || [],
    redirect: buildCpMGkeCAXVMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/build.vue").then(m => m.default || m)
  },
  {
    name: dispatchvb3lwZEhZ5Meta?.name ?? "assembly-dispatch",
    path: dispatchvb3lwZEhZ5Meta?.path ?? "dispatch",
    meta: dispatchvb3lwZEhZ5Meta || {},
    alias: dispatchvb3lwZEhZ5Meta?.alias || [],
    redirect: dispatchvb3lwZEhZ5Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/dispatch.vue").then(m => m.default || m)
  }
],
    meta: assemblySaFcWSN38MMeta || {},
    alias: assemblySaFcWSN38MMeta?.alias || [],
    redirect: assemblySaFcWSN38MMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly.vue").then(m => m.default || m)
  },
  {
    name: assemblySaFcWSN38MMeta?.name ?? "assembly___en",
    path: assemblySaFcWSN38MMeta?.path ?? "/en/assembly",
    children: [
  {
    name: assembly_45linetOmKYqShj9Meta?.name ?? "assembly-assembly-line___en",
    path: assembly_45linetOmKYqShj9Meta?.path ?? "assembly-line",
    meta: assembly_45linetOmKYqShj9Meta || {},
    alias: assembly_45linetOmKYqShj9Meta?.alias || [],
    redirect: assembly_45linetOmKYqShj9Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/assembly-line.vue").then(m => m.default || m)
  },
  {
    name: buildCpMGkeCAXVMeta?.name ?? "assembly-build___en",
    path: buildCpMGkeCAXVMeta?.path ?? "build",
    meta: buildCpMGkeCAXVMeta || {},
    alias: buildCpMGkeCAXVMeta?.alias || [],
    redirect: buildCpMGkeCAXVMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/build.vue").then(m => m.default || m)
  },
  {
    name: dispatchvb3lwZEhZ5Meta?.name ?? "assembly-dispatch___en",
    path: dispatchvb3lwZEhZ5Meta?.path ?? "dispatch",
    meta: dispatchvb3lwZEhZ5Meta || {},
    alias: dispatchvb3lwZEhZ5Meta?.alias || [],
    redirect: dispatchvb3lwZEhZ5Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/dispatch.vue").then(m => m.default || m)
  }
],
    meta: assemblySaFcWSN38MMeta || {},
    alias: assemblySaFcWSN38MMeta?.alias || [],
    redirect: assemblySaFcWSN38MMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly.vue").then(m => m.default || m)
  },
  {
    name: assemblySaFcWSN38MMeta?.name ?? "assembly___fr",
    path: assemblySaFcWSN38MMeta?.path ?? "/fr/assembly",
    children: [
  {
    name: assembly_45linetOmKYqShj9Meta?.name ?? "assembly-assembly-line___fr",
    path: assembly_45linetOmKYqShj9Meta?.path ?? "assembly-line",
    meta: assembly_45linetOmKYqShj9Meta || {},
    alias: assembly_45linetOmKYqShj9Meta?.alias || [],
    redirect: assembly_45linetOmKYqShj9Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/assembly-line.vue").then(m => m.default || m)
  },
  {
    name: buildCpMGkeCAXVMeta?.name ?? "assembly-build___fr",
    path: buildCpMGkeCAXVMeta?.path ?? "build",
    meta: buildCpMGkeCAXVMeta || {},
    alias: buildCpMGkeCAXVMeta?.alias || [],
    redirect: buildCpMGkeCAXVMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/build.vue").then(m => m.default || m)
  },
  {
    name: dispatchvb3lwZEhZ5Meta?.name ?? "assembly-dispatch___fr",
    path: dispatchvb3lwZEhZ5Meta?.path ?? "dispatch",
    meta: dispatchvb3lwZEhZ5Meta || {},
    alias: dispatchvb3lwZEhZ5Meta?.alias || [],
    redirect: dispatchvb3lwZEhZ5Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly/dispatch.vue").then(m => m.default || m)
  }
],
    meta: assemblySaFcWSN38MMeta || {},
    alias: assemblySaFcWSN38MMeta?.alias || [],
    redirect: assemblySaFcWSN38MMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/assembly.vue").then(m => m.default || m)
  },
  {
    path: bank9kxkogiVEBMeta?.path ?? "/bank",
    children: [
  {
    name: indexxwI8jysl8TMeta?.name ?? "bank",
    path: indexxwI8jysl8TMeta?.path ?? "",
    meta: indexxwI8jysl8TMeta || {},
    alias: indexxwI8jysl8TMeta?.alias || [],
    redirect: indexxwI8jysl8TMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/bank/index.vue").then(m => m.default || m)
  }
],
    name: bank9kxkogiVEBMeta?.name ?? undefined,
    meta: bank9kxkogiVEBMeta || {},
    alias: bank9kxkogiVEBMeta?.alias || [],
    redirect: bank9kxkogiVEBMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/bank.vue").then(m => m.default || m)
  },
  {
    path: bank9kxkogiVEBMeta?.path ?? "/en/bank",
    children: [
  {
    name: indexxwI8jysl8TMeta?.name ?? "bank___en",
    path: indexxwI8jysl8TMeta?.path ?? "",
    meta: indexxwI8jysl8TMeta || {},
    alias: indexxwI8jysl8TMeta?.alias || [],
    redirect: indexxwI8jysl8TMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/bank/index.vue").then(m => m.default || m)
  }
],
    name: bank9kxkogiVEBMeta?.name ?? undefined,
    meta: bank9kxkogiVEBMeta || {},
    alias: bank9kxkogiVEBMeta?.alias || [],
    redirect: bank9kxkogiVEBMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/bank.vue").then(m => m.default || m)
  },
  {
    path: bank9kxkogiVEBMeta?.path ?? "/fr/bank",
    children: [
  {
    name: indexxwI8jysl8TMeta?.name ?? "bank___fr",
    path: indexxwI8jysl8TMeta?.path ?? "",
    meta: indexxwI8jysl8TMeta || {},
    alias: indexxwI8jysl8TMeta?.alias || [],
    redirect: indexxwI8jysl8TMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/bank/index.vue").then(m => m.default || m)
  }
],
    name: bank9kxkogiVEBMeta?.name ?? undefined,
    meta: bank9kxkogiVEBMeta || {},
    alias: bank9kxkogiVEBMeta?.alias || [],
    redirect: bank9kxkogiVEBMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/bank.vue").then(m => m.default || m)
  },
  {
    name: creditsJu2NiS8y1HMeta?.name ?? "credits",
    path: creditsJu2NiS8y1HMeta?.path ?? "/credits",
    meta: creditsJu2NiS8y1HMeta || {},
    alias: creditsJu2NiS8y1HMeta?.alias || [],
    redirect: creditsJu2NiS8y1HMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/credits.vue").then(m => m.default || m)
  },
  {
    name: creditsJu2NiS8y1HMeta?.name ?? "credits___en",
    path: creditsJu2NiS8y1HMeta?.path ?? "/en/credits",
    meta: creditsJu2NiS8y1HMeta || {},
    alias: creditsJu2NiS8y1HMeta?.alias || [],
    redirect: creditsJu2NiS8y1HMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/credits.vue").then(m => m.default || m)
  },
  {
    name: creditsJu2NiS8y1HMeta?.name ?? "credits___fr",
    path: creditsJu2NiS8y1HMeta?.path ?? "/fr/credits",
    meta: creditsJu2NiS8y1HMeta || {},
    alias: creditsJu2NiS8y1HMeta?.alias || [],
    redirect: creditsJu2NiS8y1HMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/credits.vue").then(m => m.default || m)
  },
  {
    name: eventyEEUGNXRC7Meta?.name ?? "event",
    path: eventyEEUGNXRC7Meta?.path ?? "/event",
    meta: eventyEEUGNXRC7Meta || {},
    alias: eventyEEUGNXRC7Meta?.alias || [],
    redirect: eventyEEUGNXRC7Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/event.vue").then(m => m.default || m)
  },
  {
    name: eventyEEUGNXRC7Meta?.name ?? "event___en",
    path: eventyEEUGNXRC7Meta?.path ?? "/en/event",
    meta: eventyEEUGNXRC7Meta || {},
    alias: eventyEEUGNXRC7Meta?.alias || [],
    redirect: eventyEEUGNXRC7Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/event.vue").then(m => m.default || m)
  },
  {
    name: eventyEEUGNXRC7Meta?.name ?? "event___fr",
    path: eventyEEUGNXRC7Meta?.path ?? "/fr/event",
    meta: eventyEEUGNXRC7Meta || {},
    alias: eventyEEUGNXRC7Meta?.alias || [],
    redirect: eventyEEUGNXRC7Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/event.vue").then(m => m.default || m)
  },
  {
    name: guig3XqvShMfqMeta?.name ?? "gui",
    path: guig3XqvShMfqMeta?.path ?? "/gui",
    meta: guig3XqvShMfqMeta || {},
    alias: guig3XqvShMfqMeta?.alias || [],
    redirect: guig3XqvShMfqMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/gui.vue").then(m => m.default || m)
  },
  {
    name: guig3XqvShMfqMeta?.name ?? "gui___en",
    path: guig3XqvShMfqMeta?.path ?? "/en/gui",
    meta: guig3XqvShMfqMeta || {},
    alias: guig3XqvShMfqMeta?.alias || [],
    redirect: guig3XqvShMfqMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/gui.vue").then(m => m.default || m)
  },
  {
    name: guig3XqvShMfqMeta?.name ?? "gui___fr",
    path: guig3XqvShMfqMeta?.path ?? "/fr/gui",
    meta: guig3XqvShMfqMeta || {},
    alias: guig3XqvShMfqMeta?.alias || [],
    redirect: guig3XqvShMfqMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/gui.vue").then(m => m.default || m)
  },
  {
    name: human_45resourcesyaEAmXTuGmMeta?.name ?? "human-resources",
    path: human_45resourcesyaEAmXTuGmMeta?.path ?? "/human-resources",
    children: [
  {
    name: assignmentjoCki7NdquMeta?.name ?? "human-resources-assignment",
    path: assignmentjoCki7NdquMeta?.path ?? "assignment",
    meta: assignmentjoCki7NdquMeta || {},
    alias: assignmentjoCki7NdquMeta?.alias || [],
    redirect: assignmentjoCki7NdquMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/assignment.vue").then(m => m.default || m)
  },
  {
    name: _91id_93S1xP6ZGlUiMeta?.name ?? "human-resources-game-pause-recruit-id",
    path: _91id_93S1xP6ZGlUiMeta?.path ?? "game-pause/recruit/:id()",
    meta: _91id_93S1xP6ZGlUiMeta || {},
    alias: _91id_93S1xP6ZGlUiMeta?.alias || [],
    redirect: _91id_93S1xP6ZGlUiMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/game-pause/recruit/[id].vue").then(m => m.default || m)
  },
  {
    name: hired_45staffaYgauZdVBfMeta?.name ?? "human-resources-hired-staff",
    path: hired_45staffaYgauZdVBfMeta?.path ?? "hired-staff",
    meta: hired_45staffaYgauZdVBfMeta || {},
    alias: hired_45staffaYgauZdVBfMeta?.alias || [],
    redirect: hired_45staffaYgauZdVBfMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/hired-staff.vue").then(m => m.default || m)
  },
  {
    name: recruitmentinJFU50IMoMeta?.name ?? "human-resources-recruitment",
    path: recruitmentinJFU50IMoMeta?.path ?? "recruitment",
    meta: recruitmentinJFU50IMoMeta || {},
    alias: recruitmentinJFU50IMoMeta?.alias || [],
    redirect: recruitmentinJFU50IMoMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/recruitment.vue").then(m => m.default || m)
  }
],
    meta: human_45resourcesyaEAmXTuGmMeta || {},
    alias: human_45resourcesyaEAmXTuGmMeta?.alias || [],
    redirect: human_45resourcesyaEAmXTuGmMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources.vue").then(m => m.default || m)
  },
  {
    name: human_45resourcesyaEAmXTuGmMeta?.name ?? "human-resources___en",
    path: human_45resourcesyaEAmXTuGmMeta?.path ?? "/en/human-resources",
    children: [
  {
    name: assignmentjoCki7NdquMeta?.name ?? "human-resources-assignment___en",
    path: assignmentjoCki7NdquMeta?.path ?? "assignment",
    meta: assignmentjoCki7NdquMeta || {},
    alias: assignmentjoCki7NdquMeta?.alias || [],
    redirect: assignmentjoCki7NdquMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/assignment.vue").then(m => m.default || m)
  },
  {
    name: _91id_93S1xP6ZGlUiMeta?.name ?? "human-resources-game-pause-recruit-id___en",
    path: _91id_93S1xP6ZGlUiMeta?.path ?? "game-pause/recruit/:id()",
    meta: _91id_93S1xP6ZGlUiMeta || {},
    alias: _91id_93S1xP6ZGlUiMeta?.alias || [],
    redirect: _91id_93S1xP6ZGlUiMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/game-pause/recruit/[id].vue").then(m => m.default || m)
  },
  {
    name: hired_45staffaYgauZdVBfMeta?.name ?? "human-resources-hired-staff___en",
    path: hired_45staffaYgauZdVBfMeta?.path ?? "hired-staff",
    meta: hired_45staffaYgauZdVBfMeta || {},
    alias: hired_45staffaYgauZdVBfMeta?.alias || [],
    redirect: hired_45staffaYgauZdVBfMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/hired-staff.vue").then(m => m.default || m)
  },
  {
    name: recruitmentinJFU50IMoMeta?.name ?? "human-resources-recruitment___en",
    path: recruitmentinJFU50IMoMeta?.path ?? "recruitment",
    meta: recruitmentinJFU50IMoMeta || {},
    alias: recruitmentinJFU50IMoMeta?.alias || [],
    redirect: recruitmentinJFU50IMoMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/recruitment.vue").then(m => m.default || m)
  }
],
    meta: human_45resourcesyaEAmXTuGmMeta || {},
    alias: human_45resourcesyaEAmXTuGmMeta?.alias || [],
    redirect: human_45resourcesyaEAmXTuGmMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources.vue").then(m => m.default || m)
  },
  {
    name: human_45resourcesyaEAmXTuGmMeta?.name ?? "human-resources___fr",
    path: human_45resourcesyaEAmXTuGmMeta?.path ?? "/fr/human-resources",
    children: [
  {
    name: assignmentjoCki7NdquMeta?.name ?? "human-resources-assignment___fr",
    path: assignmentjoCki7NdquMeta?.path ?? "assignment",
    meta: assignmentjoCki7NdquMeta || {},
    alias: assignmentjoCki7NdquMeta?.alias || [],
    redirect: assignmentjoCki7NdquMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/assignment.vue").then(m => m.default || m)
  },
  {
    name: _91id_93S1xP6ZGlUiMeta?.name ?? "human-resources-game-pause-recruit-id___fr",
    path: _91id_93S1xP6ZGlUiMeta?.path ?? "game-pause/recruit/:id()",
    meta: _91id_93S1xP6ZGlUiMeta || {},
    alias: _91id_93S1xP6ZGlUiMeta?.alias || [],
    redirect: _91id_93S1xP6ZGlUiMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/game-pause/recruit/[id].vue").then(m => m.default || m)
  },
  {
    name: hired_45staffaYgauZdVBfMeta?.name ?? "human-resources-hired-staff___fr",
    path: hired_45staffaYgauZdVBfMeta?.path ?? "hired-staff",
    meta: hired_45staffaYgauZdVBfMeta || {},
    alias: hired_45staffaYgauZdVBfMeta?.alias || [],
    redirect: hired_45staffaYgauZdVBfMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/hired-staff.vue").then(m => m.default || m)
  },
  {
    name: recruitmentinJFU50IMoMeta?.name ?? "human-resources-recruitment___fr",
    path: recruitmentinJFU50IMoMeta?.path ?? "recruitment",
    meta: recruitmentinJFU50IMoMeta || {},
    alias: recruitmentinJFU50IMoMeta?.alias || [],
    redirect: recruitmentinJFU50IMoMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources/recruitment.vue").then(m => m.default || m)
  }
],
    meta: human_45resourcesyaEAmXTuGmMeta || {},
    alias: human_45resourcesyaEAmXTuGmMeta?.alias || [],
    redirect: human_45resourcesyaEAmXTuGmMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/human-resources.vue").then(m => m.default || m)
  },
  {
    name: indexn4bLLTEo2hMeta?.name ?? "index",
    path: indexn4bLLTEo2hMeta?.path ?? "/",
    meta: indexn4bLLTEo2hMeta || {},
    alias: indexn4bLLTEo2hMeta?.alias || [],
    redirect: indexn4bLLTEo2hMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexn4bLLTEo2hMeta?.name ?? "index___en",
    path: indexn4bLLTEo2hMeta?.path ?? "/en",
    meta: indexn4bLLTEo2hMeta || {},
    alias: indexn4bLLTEo2hMeta?.alias || [],
    redirect: indexn4bLLTEo2hMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexn4bLLTEo2hMeta?.name ?? "index___fr",
    path: indexn4bLLTEo2hMeta?.path ?? "/fr",
    meta: indexn4bLLTEo2hMeta || {},
    alias: indexn4bLLTEo2hMeta?.alias || [],
    redirect: indexn4bLLTEo2hMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: introPAZEShRNt0Meta?.name ?? "intro",
    path: introPAZEShRNt0Meta?.path ?? "/intro",
    meta: introPAZEShRNt0Meta || {},
    alias: introPAZEShRNt0Meta?.alias || [],
    redirect: introPAZEShRNt0Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/intro.vue").then(m => m.default || m)
  },
  {
    name: introPAZEShRNt0Meta?.name ?? "intro___en",
    path: introPAZEShRNt0Meta?.path ?? "/en/intro",
    meta: introPAZEShRNt0Meta || {},
    alias: introPAZEShRNt0Meta?.alias || [],
    redirect: introPAZEShRNt0Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/intro.vue").then(m => m.default || m)
  },
  {
    name: introPAZEShRNt0Meta?.name ?? "intro___fr",
    path: introPAZEShRNt0Meta?.path ?? "/fr/intro",
    meta: introPAZEShRNt0Meta || {},
    alias: introPAZEShRNt0Meta?.alias || [],
    redirect: introPAZEShRNt0Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/intro.vue").then(m => m.default || m)
  },
  {
    name: load_45gamen2HMcHKXb8Meta?.name ?? "load-game",
    path: load_45gamen2HMcHKXb8Meta?.path ?? "/load-game",
    meta: load_45gamen2HMcHKXb8Meta || {},
    alias: load_45gamen2HMcHKXb8Meta?.alias || [],
    redirect: load_45gamen2HMcHKXb8Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/load-game.vue").then(m => m.default || m)
  },
  {
    name: load_45gamen2HMcHKXb8Meta?.name ?? "load-game___en",
    path: load_45gamen2HMcHKXb8Meta?.path ?? "/en/load-game",
    meta: load_45gamen2HMcHKXb8Meta || {},
    alias: load_45gamen2HMcHKXb8Meta?.alias || [],
    redirect: load_45gamen2HMcHKXb8Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/load-game.vue").then(m => m.default || m)
  },
  {
    name: load_45gamen2HMcHKXb8Meta?.name ?? "load-game___fr",
    path: load_45gamen2HMcHKXb8Meta?.path ?? "/fr/load-game",
    meta: load_45gamen2HMcHKXb8Meta || {},
    alias: load_45gamen2HMcHKXb8Meta?.alias || [],
    redirect: load_45gamen2HMcHKXb8Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/load-game.vue").then(m => m.default || m)
  },
  {
    name: market_45studyWbRmEQCJUaMeta?.name ?? "market-study",
    path: market_45studyWbRmEQCJUaMeta?.path ?? "/market-study",
    children: [
  {
    name: competitors1vh2NpzNxCMeta?.name ?? "market-study-competitors",
    path: competitors1vh2NpzNxCMeta?.path ?? "competitors",
    meta: competitors1vh2NpzNxCMeta || {},
    alias: competitors1vh2NpzNxCMeta?.alias || [],
    redirect: competitors1vh2NpzNxCMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/competitors.vue").then(m => m.default || m)
  },
  {
    name: steal50AWpinOcHMeta?.name ?? "market-study-game-pause-steal",
    path: steal50AWpinOcHMeta?.path ?? "game-pause/steal",
    meta: steal50AWpinOcHMeta || {},
    alias: steal50AWpinOcHMeta?.alias || [],
    redirect: steal50AWpinOcHMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/game-pause/steal.vue").then(m => m.default || m)
  },
  {
    name: my_45companyhZsVlBQtRMMeta?.name ?? "market-study-my-company",
    path: my_45companyhZsVlBQtRMMeta?.path ?? "my-company",
    meta: my_45companyhZsVlBQtRMMeta || {},
    alias: my_45companyhZsVlBQtRMMeta?.alias || [],
    redirect: my_45companyhZsVlBQtRMMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/my-company.vue").then(m => m.default || m)
  },
  {
    name: steal_45agreementsyo2ILXc3YzMeta?.name ?? "market-study-steal-agreements",
    path: steal_45agreementsyo2ILXc3YzMeta?.path ?? "steal-agreements",
    meta: steal_45agreementsyo2ILXc3YzMeta || {},
    alias: steal_45agreementsyo2ILXc3YzMeta?.alias || [],
    redirect: steal_45agreementsyo2ILXc3YzMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/steal-agreements.vue").then(m => m.default || m)
  }
],
    meta: market_45studyWbRmEQCJUaMeta || {},
    alias: market_45studyWbRmEQCJUaMeta?.alias || [],
    redirect: market_45studyWbRmEQCJUaMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study.vue").then(m => m.default || m)
  },
  {
    name: market_45studyWbRmEQCJUaMeta?.name ?? "market-study___en",
    path: market_45studyWbRmEQCJUaMeta?.path ?? "/en/market-study",
    children: [
  {
    name: competitors1vh2NpzNxCMeta?.name ?? "market-study-competitors___en",
    path: competitors1vh2NpzNxCMeta?.path ?? "competitors",
    meta: competitors1vh2NpzNxCMeta || {},
    alias: competitors1vh2NpzNxCMeta?.alias || [],
    redirect: competitors1vh2NpzNxCMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/competitors.vue").then(m => m.default || m)
  },
  {
    name: steal50AWpinOcHMeta?.name ?? "market-study-game-pause-steal___en",
    path: steal50AWpinOcHMeta?.path ?? "game-pause/steal",
    meta: steal50AWpinOcHMeta || {},
    alias: steal50AWpinOcHMeta?.alias || [],
    redirect: steal50AWpinOcHMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/game-pause/steal.vue").then(m => m.default || m)
  },
  {
    name: my_45companyhZsVlBQtRMMeta?.name ?? "market-study-my-company___en",
    path: my_45companyhZsVlBQtRMMeta?.path ?? "my-company",
    meta: my_45companyhZsVlBQtRMMeta || {},
    alias: my_45companyhZsVlBQtRMMeta?.alias || [],
    redirect: my_45companyhZsVlBQtRMMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/my-company.vue").then(m => m.default || m)
  },
  {
    name: steal_45agreementsyo2ILXc3YzMeta?.name ?? "market-study-steal-agreements___en",
    path: steal_45agreementsyo2ILXc3YzMeta?.path ?? "steal-agreements",
    meta: steal_45agreementsyo2ILXc3YzMeta || {},
    alias: steal_45agreementsyo2ILXc3YzMeta?.alias || [],
    redirect: steal_45agreementsyo2ILXc3YzMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/steal-agreements.vue").then(m => m.default || m)
  }
],
    meta: market_45studyWbRmEQCJUaMeta || {},
    alias: market_45studyWbRmEQCJUaMeta?.alias || [],
    redirect: market_45studyWbRmEQCJUaMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study.vue").then(m => m.default || m)
  },
  {
    name: market_45studyWbRmEQCJUaMeta?.name ?? "market-study___fr",
    path: market_45studyWbRmEQCJUaMeta?.path ?? "/fr/market-study",
    children: [
  {
    name: competitors1vh2NpzNxCMeta?.name ?? "market-study-competitors___fr",
    path: competitors1vh2NpzNxCMeta?.path ?? "competitors",
    meta: competitors1vh2NpzNxCMeta || {},
    alias: competitors1vh2NpzNxCMeta?.alias || [],
    redirect: competitors1vh2NpzNxCMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/competitors.vue").then(m => m.default || m)
  },
  {
    name: steal50AWpinOcHMeta?.name ?? "market-study-game-pause-steal___fr",
    path: steal50AWpinOcHMeta?.path ?? "game-pause/steal",
    meta: steal50AWpinOcHMeta || {},
    alias: steal50AWpinOcHMeta?.alias || [],
    redirect: steal50AWpinOcHMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/game-pause/steal.vue").then(m => m.default || m)
  },
  {
    name: my_45companyhZsVlBQtRMMeta?.name ?? "market-study-my-company___fr",
    path: my_45companyhZsVlBQtRMMeta?.path ?? "my-company",
    meta: my_45companyhZsVlBQtRMMeta || {},
    alias: my_45companyhZsVlBQtRMMeta?.alias || [],
    redirect: my_45companyhZsVlBQtRMMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/my-company.vue").then(m => m.default || m)
  },
  {
    name: steal_45agreementsyo2ILXc3YzMeta?.name ?? "market-study-steal-agreements___fr",
    path: steal_45agreementsyo2ILXc3YzMeta?.path ?? "steal-agreements",
    meta: steal_45agreementsyo2ILXc3YzMeta || {},
    alias: steal_45agreementsyo2ILXc3YzMeta?.alias || [],
    redirect: steal_45agreementsyo2ILXc3YzMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study/steal-agreements.vue").then(m => m.default || m)
  }
],
    meta: market_45studyWbRmEQCJUaMeta || {},
    alias: market_45studyWbRmEQCJUaMeta?.alias || [],
    redirect: market_45studyWbRmEQCJUaMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/market-study.vue").then(m => m.default || m)
  },
  {
    name: new_45gameqHjwPvWuVlMeta?.name ?? "new-game",
    path: new_45gameqHjwPvWuVlMeta?.path ?? "/new-game",
    meta: new_45gameqHjwPvWuVlMeta || {},
    alias: new_45gameqHjwPvWuVlMeta?.alias || [],
    redirect: new_45gameqHjwPvWuVlMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/new-game.vue").then(m => m.default || m)
  },
  {
    name: new_45gameqHjwPvWuVlMeta?.name ?? "new-game___en",
    path: new_45gameqHjwPvWuVlMeta?.path ?? "/en/new-game",
    meta: new_45gameqHjwPvWuVlMeta || {},
    alias: new_45gameqHjwPvWuVlMeta?.alias || [],
    redirect: new_45gameqHjwPvWuVlMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/new-game.vue").then(m => m.default || m)
  },
  {
    name: new_45gameqHjwPvWuVlMeta?.name ?? "new-game___fr",
    path: new_45gameqHjwPvWuVlMeta?.path ?? "/fr/new-game",
    meta: new_45gameqHjwPvWuVlMeta || {},
    alias: new_45gameqHjwPvWuVlMeta?.alias || [],
    redirect: new_45gameqHjwPvWuVlMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/new-game.vue").then(m => m.default || m)
  },
  {
    name: researchhnLZSXXuHlMeta?.name ?? "research",
    path: researchhnLZSXXuHlMeta?.path ?? "/research",
    children: [
  {
    name: overviewBEwFPBJbQYMeta?.name ?? "research-overview",
    path: overviewBEwFPBJbQYMeta?.path ?? "overview",
    meta: overviewBEwFPBJbQYMeta || {},
    alias: overviewBEwFPBJbQYMeta?.alias || [],
    redirect: overviewBEwFPBJbQYMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/research/overview.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PI1thfzLz2Meta?.name ?? "research-research-tree-id",
    path: _91id_93PI1thfzLz2Meta?.path ?? "research-tree/:id()",
    meta: _91id_93PI1thfzLz2Meta || {},
    alias: _91id_93PI1thfzLz2Meta?.alias || [],
    redirect: _91id_93PI1thfzLz2Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/research/research-tree/[id].vue").then(m => m.default || m)
  }
],
    meta: researchhnLZSXXuHlMeta || {},
    alias: researchhnLZSXXuHlMeta?.alias || [],
    redirect: researchhnLZSXXuHlMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/research.vue").then(m => m.default || m)
  },
  {
    name: researchhnLZSXXuHlMeta?.name ?? "research___en",
    path: researchhnLZSXXuHlMeta?.path ?? "/en/research",
    children: [
  {
    name: overviewBEwFPBJbQYMeta?.name ?? "research-overview___en",
    path: overviewBEwFPBJbQYMeta?.path ?? "overview",
    meta: overviewBEwFPBJbQYMeta || {},
    alias: overviewBEwFPBJbQYMeta?.alias || [],
    redirect: overviewBEwFPBJbQYMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/research/overview.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PI1thfzLz2Meta?.name ?? "research-research-tree-id___en",
    path: _91id_93PI1thfzLz2Meta?.path ?? "research-tree/:id()",
    meta: _91id_93PI1thfzLz2Meta || {},
    alias: _91id_93PI1thfzLz2Meta?.alias || [],
    redirect: _91id_93PI1thfzLz2Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/research/research-tree/[id].vue").then(m => m.default || m)
  }
],
    meta: researchhnLZSXXuHlMeta || {},
    alias: researchhnLZSXXuHlMeta?.alias || [],
    redirect: researchhnLZSXXuHlMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/research.vue").then(m => m.default || m)
  },
  {
    name: researchhnLZSXXuHlMeta?.name ?? "research___fr",
    path: researchhnLZSXXuHlMeta?.path ?? "/fr/research",
    children: [
  {
    name: overviewBEwFPBJbQYMeta?.name ?? "research-overview___fr",
    path: overviewBEwFPBJbQYMeta?.path ?? "overview",
    meta: overviewBEwFPBJbQYMeta || {},
    alias: overviewBEwFPBJbQYMeta?.alias || [],
    redirect: overviewBEwFPBJbQYMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/research/overview.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PI1thfzLz2Meta?.name ?? "research-research-tree-id___fr",
    path: _91id_93PI1thfzLz2Meta?.path ?? "research-tree/:id()",
    meta: _91id_93PI1thfzLz2Meta || {},
    alias: _91id_93PI1thfzLz2Meta?.alias || [],
    redirect: _91id_93PI1thfzLz2Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/research/research-tree/[id].vue").then(m => m.default || m)
  }
],
    meta: researchhnLZSXXuHlMeta || {},
    alias: researchhnLZSXXuHlMeta?.alias || [],
    redirect: researchhnLZSXXuHlMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/research.vue").then(m => m.default || m)
  },
  {
    name: results0QgZAOswI0Meta?.name ?? "results",
    path: results0QgZAOswI0Meta?.path ?? "/results",
    meta: results0QgZAOswI0Meta || {},
    alias: results0QgZAOswI0Meta?.alias || [],
    redirect: results0QgZAOswI0Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/results.vue").then(m => m.default || m)
  },
  {
    name: results0QgZAOswI0Meta?.name ?? "results___en",
    path: results0QgZAOswI0Meta?.path ?? "/en/results",
    meta: results0QgZAOswI0Meta || {},
    alias: results0QgZAOswI0Meta?.alias || [],
    redirect: results0QgZAOswI0Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/results.vue").then(m => m.default || m)
  },
  {
    name: results0QgZAOswI0Meta?.name ?? "results___fr",
    path: results0QgZAOswI0Meta?.path ?? "/fr/results",
    meta: results0QgZAOswI0Meta || {},
    alias: results0QgZAOswI0Meta?.alias || [],
    redirect: results0QgZAOswI0Meta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/results.vue").then(m => m.default || m)
  },
  {
    name: salesIfDIQhfEduMeta?.name ?? "sales",
    path: salesIfDIQhfEduMeta?.path ?? "/sales",
    children: [
  {
    name: _91id_93DpP7kRfSpmMeta?.name ?? "sales-nation-contract-game-pause-drop-id",
    path: _91id_93DpP7kRfSpmMeta?.path ?? ":nation()/contract/game-pause/drop/:id()",
    meta: _91id_93DpP7kRfSpmMeta || {},
    alias: _91id_93DpP7kRfSpmMeta?.alias || [],
    redirect: _91id_93DpP7kRfSpmMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/contract/game-pause/drop/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Tw6VfkBGxoMeta?.name ?? "sales-nation-contract-game-pause-sign-id",
    path: _91id_93Tw6VfkBGxoMeta?.path ?? ":nation()/contract/game-pause/sign/:id()",
    meta: _91id_93Tw6VfkBGxoMeta || {},
    alias: _91id_93Tw6VfkBGxoMeta?.alias || [],
    redirect: _91id_93Tw6VfkBGxoMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/contract/game-pause/sign/[id].vue").then(m => m.default || m)
  },
  {
    name: marketCm96BoXR2eMeta?.name ?? "sales-nation-market",
    path: marketCm96BoXR2eMeta?.path ?? ":nation()/market",
    meta: marketCm96BoXR2eMeta || {},
    alias: marketCm96BoXR2eMeta?.alias || [],
    redirect: marketCm96BoXR2eMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/market.vue").then(m => m.default || m)
  },
  {
    name: partnership_45agreementsSfAahs7z4uMeta?.name ?? "sales-nation-partnership-agreements",
    path: partnership_45agreementsSfAahs7z4uMeta?.path ?? ":nation()/partnership-agreements",
    meta: partnership_45agreementsSfAahs7z4uMeta || {},
    alias: partnership_45agreementsSfAahs7z4uMeta?.alias || [],
    redirect: partnership_45agreementsSfAahs7z4uMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/partnership-agreements.vue").then(m => m.default || m)
  },
  {
    name: maprRWPfaiYDbMeta?.name ?? "sales-map",
    path: maprRWPfaiYDbMeta?.path ?? "map",
    meta: maprRWPfaiYDbMeta || {},
    alias: maprRWPfaiYDbMeta?.alias || [],
    redirect: maprRWPfaiYDbMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/map.vue").then(m => m.default || m)
  }
],
    meta: salesIfDIQhfEduMeta || {},
    alias: salesIfDIQhfEduMeta?.alias || [],
    redirect: salesIfDIQhfEduMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales.vue").then(m => m.default || m)
  },
  {
    name: salesIfDIQhfEduMeta?.name ?? "sales___en",
    path: salesIfDIQhfEduMeta?.path ?? "/en/sales",
    children: [
  {
    name: _91id_93DpP7kRfSpmMeta?.name ?? "sales-nation-contract-game-pause-drop-id___en",
    path: _91id_93DpP7kRfSpmMeta?.path ?? ":nation()/contract/game-pause/drop/:id()",
    meta: _91id_93DpP7kRfSpmMeta || {},
    alias: _91id_93DpP7kRfSpmMeta?.alias || [],
    redirect: _91id_93DpP7kRfSpmMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/contract/game-pause/drop/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Tw6VfkBGxoMeta?.name ?? "sales-nation-contract-game-pause-sign-id___en",
    path: _91id_93Tw6VfkBGxoMeta?.path ?? ":nation()/contract/game-pause/sign/:id()",
    meta: _91id_93Tw6VfkBGxoMeta || {},
    alias: _91id_93Tw6VfkBGxoMeta?.alias || [],
    redirect: _91id_93Tw6VfkBGxoMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/contract/game-pause/sign/[id].vue").then(m => m.default || m)
  },
  {
    name: marketCm96BoXR2eMeta?.name ?? "sales-nation-market___en",
    path: marketCm96BoXR2eMeta?.path ?? ":nation()/market",
    meta: marketCm96BoXR2eMeta || {},
    alias: marketCm96BoXR2eMeta?.alias || [],
    redirect: marketCm96BoXR2eMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/market.vue").then(m => m.default || m)
  },
  {
    name: partnership_45agreementsSfAahs7z4uMeta?.name ?? "sales-nation-partnership-agreements___en",
    path: partnership_45agreementsSfAahs7z4uMeta?.path ?? ":nation()/partnership-agreements",
    meta: partnership_45agreementsSfAahs7z4uMeta || {},
    alias: partnership_45agreementsSfAahs7z4uMeta?.alias || [],
    redirect: partnership_45agreementsSfAahs7z4uMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/partnership-agreements.vue").then(m => m.default || m)
  },
  {
    name: maprRWPfaiYDbMeta?.name ?? "sales-map___en",
    path: maprRWPfaiYDbMeta?.path ?? "map",
    meta: maprRWPfaiYDbMeta || {},
    alias: maprRWPfaiYDbMeta?.alias || [],
    redirect: maprRWPfaiYDbMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/map.vue").then(m => m.default || m)
  }
],
    meta: salesIfDIQhfEduMeta || {},
    alias: salesIfDIQhfEduMeta?.alias || [],
    redirect: salesIfDIQhfEduMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales.vue").then(m => m.default || m)
  },
  {
    name: salesIfDIQhfEduMeta?.name ?? "sales___fr",
    path: salesIfDIQhfEduMeta?.path ?? "/fr/sales",
    children: [
  {
    name: _91id_93DpP7kRfSpmMeta?.name ?? "sales-nation-contract-game-pause-drop-id___fr",
    path: _91id_93DpP7kRfSpmMeta?.path ?? ":nation()/contract/game-pause/drop/:id()",
    meta: _91id_93DpP7kRfSpmMeta || {},
    alias: _91id_93DpP7kRfSpmMeta?.alias || [],
    redirect: _91id_93DpP7kRfSpmMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/contract/game-pause/drop/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Tw6VfkBGxoMeta?.name ?? "sales-nation-contract-game-pause-sign-id___fr",
    path: _91id_93Tw6VfkBGxoMeta?.path ?? ":nation()/contract/game-pause/sign/:id()",
    meta: _91id_93Tw6VfkBGxoMeta || {},
    alias: _91id_93Tw6VfkBGxoMeta?.alias || [],
    redirect: _91id_93Tw6VfkBGxoMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/contract/game-pause/sign/[id].vue").then(m => m.default || m)
  },
  {
    name: marketCm96BoXR2eMeta?.name ?? "sales-nation-market___fr",
    path: marketCm96BoXR2eMeta?.path ?? ":nation()/market",
    meta: marketCm96BoXR2eMeta || {},
    alias: marketCm96BoXR2eMeta?.alias || [],
    redirect: marketCm96BoXR2eMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/market.vue").then(m => m.default || m)
  },
  {
    name: partnership_45agreementsSfAahs7z4uMeta?.name ?? "sales-nation-partnership-agreements___fr",
    path: partnership_45agreementsSfAahs7z4uMeta?.path ?? ":nation()/partnership-agreements",
    meta: partnership_45agreementsSfAahs7z4uMeta || {},
    alias: partnership_45agreementsSfAahs7z4uMeta?.alias || [],
    redirect: partnership_45agreementsSfAahs7z4uMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/[nation]/partnership-agreements.vue").then(m => m.default || m)
  },
  {
    name: maprRWPfaiYDbMeta?.name ?? "sales-map___fr",
    path: maprRWPfaiYDbMeta?.path ?? "map",
    meta: maprRWPfaiYDbMeta || {},
    alias: maprRWPfaiYDbMeta?.alias || [],
    redirect: maprRWPfaiYDbMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales/map.vue").then(m => m.default || m)
  }
],
    meta: salesIfDIQhfEduMeta || {},
    alias: salesIfDIQhfEduMeta?.alias || [],
    redirect: salesIfDIQhfEduMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/sales.vue").then(m => m.default || m)
  },
  {
    name: save_45game2m6GOB9YJoMeta?.name ?? "save-game",
    path: save_45game2m6GOB9YJoMeta?.path ?? "/save-game",
    meta: save_45game2m6GOB9YJoMeta || {},
    alias: save_45game2m6GOB9YJoMeta?.alias || [],
    redirect: save_45game2m6GOB9YJoMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/save-game.vue").then(m => m.default || m)
  },
  {
    name: save_45game2m6GOB9YJoMeta?.name ?? "save-game___en",
    path: save_45game2m6GOB9YJoMeta?.path ?? "/en/save-game",
    meta: save_45game2m6GOB9YJoMeta || {},
    alias: save_45game2m6GOB9YJoMeta?.alias || [],
    redirect: save_45game2m6GOB9YJoMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/save-game.vue").then(m => m.default || m)
  },
  {
    name: save_45game2m6GOB9YJoMeta?.name ?? "save-game___fr",
    path: save_45game2m6GOB9YJoMeta?.path ?? "/fr/save-game",
    meta: save_45game2m6GOB9YJoMeta || {},
    alias: save_45game2m6GOB9YJoMeta?.alias || [],
    redirect: save_45game2m6GOB9YJoMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/save-game.vue").then(m => m.default || m)
  },
  {
    name: warehousev5PYxfjGOxMeta?.name ?? "warehouse",
    path: warehousev5PYxfjGOxMeta?.path ?? "/warehouse",
    children: [
  {
    name: _91id_93dibvzyxz9HMeta?.name ?? "warehouse-contract-game-pause-drop-id",
    path: _91id_93dibvzyxz9HMeta?.path ?? "contract/game-pause/drop/:id()",
    meta: _91id_93dibvzyxz9HMeta || {},
    alias: _91id_93dibvzyxz9HMeta?.alias || [],
    redirect: _91id_93dibvzyxz9HMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/contract/game-pause/drop/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93rBmBBdwdIsMeta?.name ?? "warehouse-contract-game-pause-sign-id",
    path: _91id_93rBmBBdwdIsMeta?.path ?? "contract/game-pause/sign/:id()",
    meta: _91id_93rBmBBdwdIsMeta || {},
    alias: _91id_93rBmBBdwdIsMeta?.alias || [],
    redirect: _91id_93rBmBBdwdIsMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/contract/game-pause/sign/[id].vue").then(m => m.default || m)
  },
  {
    name: marketj4DSafke9RMeta?.name ?? "warehouse-market",
    path: marketj4DSafke9RMeta?.path ?? "market",
    meta: marketj4DSafke9RMeta || {},
    alias: marketj4DSafke9RMeta?.alias || [],
    redirect: marketj4DSafke9RMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/market.vue").then(m => m.default || m)
  },
  {
    name: material_45storage0irbfwa3JXMeta?.name ?? "warehouse-material-storage",
    path: material_45storage0irbfwa3JXMeta?.path ?? "material-storage",
    meta: material_45storage0irbfwa3JXMeta || {},
    alias: material_45storage0irbfwa3JXMeta?.alias || [],
    redirect: material_45storage0irbfwa3JXMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/material-storage.vue").then(m => m.default || m)
  },
  {
    name: supply7DqQHEKBbhMeta?.name ?? "warehouse-supply",
    path: supply7DqQHEKBbhMeta?.path ?? "supply",
    meta: supply7DqQHEKBbhMeta || {},
    alias: supply7DqQHEKBbhMeta?.alias || [],
    redirect: supply7DqQHEKBbhMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/supply.vue").then(m => m.default || m)
  }
],
    meta: warehousev5PYxfjGOxMeta || {},
    alias: warehousev5PYxfjGOxMeta?.alias || [],
    redirect: warehousev5PYxfjGOxMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse.vue").then(m => m.default || m)
  },
  {
    name: warehousev5PYxfjGOxMeta?.name ?? "warehouse___en",
    path: warehousev5PYxfjGOxMeta?.path ?? "/en/warehouse",
    children: [
  {
    name: _91id_93dibvzyxz9HMeta?.name ?? "warehouse-contract-game-pause-drop-id___en",
    path: _91id_93dibvzyxz9HMeta?.path ?? "contract/game-pause/drop/:id()",
    meta: _91id_93dibvzyxz9HMeta || {},
    alias: _91id_93dibvzyxz9HMeta?.alias || [],
    redirect: _91id_93dibvzyxz9HMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/contract/game-pause/drop/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93rBmBBdwdIsMeta?.name ?? "warehouse-contract-game-pause-sign-id___en",
    path: _91id_93rBmBBdwdIsMeta?.path ?? "contract/game-pause/sign/:id()",
    meta: _91id_93rBmBBdwdIsMeta || {},
    alias: _91id_93rBmBBdwdIsMeta?.alias || [],
    redirect: _91id_93rBmBBdwdIsMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/contract/game-pause/sign/[id].vue").then(m => m.default || m)
  },
  {
    name: marketj4DSafke9RMeta?.name ?? "warehouse-market___en",
    path: marketj4DSafke9RMeta?.path ?? "market",
    meta: marketj4DSafke9RMeta || {},
    alias: marketj4DSafke9RMeta?.alias || [],
    redirect: marketj4DSafke9RMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/market.vue").then(m => m.default || m)
  },
  {
    name: material_45storage0irbfwa3JXMeta?.name ?? "warehouse-material-storage___en",
    path: material_45storage0irbfwa3JXMeta?.path ?? "material-storage",
    meta: material_45storage0irbfwa3JXMeta || {},
    alias: material_45storage0irbfwa3JXMeta?.alias || [],
    redirect: material_45storage0irbfwa3JXMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/material-storage.vue").then(m => m.default || m)
  },
  {
    name: supply7DqQHEKBbhMeta?.name ?? "warehouse-supply___en",
    path: supply7DqQHEKBbhMeta?.path ?? "supply",
    meta: supply7DqQHEKBbhMeta || {},
    alias: supply7DqQHEKBbhMeta?.alias || [],
    redirect: supply7DqQHEKBbhMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/supply.vue").then(m => m.default || m)
  }
],
    meta: warehousev5PYxfjGOxMeta || {},
    alias: warehousev5PYxfjGOxMeta?.alias || [],
    redirect: warehousev5PYxfjGOxMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse.vue").then(m => m.default || m)
  },
  {
    name: warehousev5PYxfjGOxMeta?.name ?? "warehouse___fr",
    path: warehousev5PYxfjGOxMeta?.path ?? "/fr/warehouse",
    children: [
  {
    name: _91id_93dibvzyxz9HMeta?.name ?? "warehouse-contract-game-pause-drop-id___fr",
    path: _91id_93dibvzyxz9HMeta?.path ?? "contract/game-pause/drop/:id()",
    meta: _91id_93dibvzyxz9HMeta || {},
    alias: _91id_93dibvzyxz9HMeta?.alias || [],
    redirect: _91id_93dibvzyxz9HMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/contract/game-pause/drop/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93rBmBBdwdIsMeta?.name ?? "warehouse-contract-game-pause-sign-id___fr",
    path: _91id_93rBmBBdwdIsMeta?.path ?? "contract/game-pause/sign/:id()",
    meta: _91id_93rBmBBdwdIsMeta || {},
    alias: _91id_93rBmBBdwdIsMeta?.alias || [],
    redirect: _91id_93rBmBBdwdIsMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/contract/game-pause/sign/[id].vue").then(m => m.default || m)
  },
  {
    name: marketj4DSafke9RMeta?.name ?? "warehouse-market___fr",
    path: marketj4DSafke9RMeta?.path ?? "market",
    meta: marketj4DSafke9RMeta || {},
    alias: marketj4DSafke9RMeta?.alias || [],
    redirect: marketj4DSafke9RMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/market.vue").then(m => m.default || m)
  },
  {
    name: material_45storage0irbfwa3JXMeta?.name ?? "warehouse-material-storage___fr",
    path: material_45storage0irbfwa3JXMeta?.path ?? "material-storage",
    meta: material_45storage0irbfwa3JXMeta || {},
    alias: material_45storage0irbfwa3JXMeta?.alias || [],
    redirect: material_45storage0irbfwa3JXMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/material-storage.vue").then(m => m.default || m)
  },
  {
    name: supply7DqQHEKBbhMeta?.name ?? "warehouse-supply___fr",
    path: supply7DqQHEKBbhMeta?.path ?? "supply",
    meta: supply7DqQHEKBbhMeta || {},
    alias: supply7DqQHEKBbhMeta?.alias || [],
    redirect: supply7DqQHEKBbhMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse/supply.vue").then(m => m.default || m)
  }
],
    meta: warehousev5PYxfjGOxMeta || {},
    alias: warehousev5PYxfjGOxMeta?.alias || [],
    redirect: warehousev5PYxfjGOxMeta?.redirect || undefined,
    component: () => import("/mnt/data/maitop/robo-nation/code/platforms/web/pages/warehouse.vue").then(m => m.default || m)
  }
]