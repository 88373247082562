<!-- here is component that will handle all the notifications -->
<script setup>
import useGameStore from '@/store/game';
import RoboNationCore from '~/composables/RoboNationCore';
import { isTesting, getFakeData } from '~/composables/fakeData';
// import useAudio from composables
import { AudioPlayer } from '~/composables/useAudio';

const { t } = useI18n();

// add a ref for notifications, set it to null
const notifications = ref([]);
const deletedNotifications = ref([]);
const nextNotification = reactive({
    data: {},
});
const notificationCount = ref(0);

// add a method to get the theme of the notification
const getTheme = (notification) => {
    let theme = Object.keys(notificationGroup.value).find(
        (key) => notificationGroup.value[key] === notification.groupId
    );
    if (theme === 'MARKET') {
        theme = 'market_study';
    }
    return theme;
};

// add a method to get the icon of the notification
const getIcon = (notification) => {
    let icon = Object.keys(notificationGroup.value).find(
        (key) => notificationGroup.value[key] === notification.groupId
    );
    if (icon === 'MARKET') {
        icon = 'market_study';
    }
    if (icon === 'GENERAL') {
        icon = 'bell';
    }
    if (icon === 'EVENTS') {
        icon = null;
    }
    return icon;
};

const toCamelCase = (str) => {
    if (!str) return str;
    return str.toLowerCase().replace(/_([a-z])/g, (match, letter) => {
        return letter.toUpperCase();
    });
};

const notificationGroup = computed(() => RoboNationCore.NOTIFICATION_GROUP);

// add a method to handle click on notification
const handleClick = (notification) => {
    // remove notification from notifications
    notifications.value = notifications.value.filter((n) => n.idSecond !== notification.idSecond);
    deletedNotifications.value.push(notification);

    AudioPlayer.playSfx("buttonclick");
};

// add a method to get the notification message
const getNotificationMessage = (notification) => {
    return RoboNationCore.instance.getTranslatedNotificationDesc(notification, (key) => t(key));
};

// add a watcher on currentDay from useGameStore
const watchCurrentDay = watch(
    () => useGameStore().currentDay,
    () => {
        // set notifications to roboNationCore instance getNotifications function
        nextNotification.data = RoboNationCore.instance.getNextNotification(useGameStore().playerID);

        // if nextNotification.data is not in notifications push it to notifications
        if (
            nextNotification.data
            // &&
            // !notifications.value.includes(nextNotification.data) &&
            // !deletedNotifications.value.includes(nextNotification.data)
        ) {
            nextNotification.data.idSecond = `${nextNotification.data.id}-${notificationCount.value}`;
            notifications.value.push(nextNotification.data);
            notificationCount.value++;

            AudioPlayer.playSfx("notification");
        }

        if (isTesting()) {
            getFakeData().notifications.forEach((notification) => {
                notifications.value.push(notification);
            });
        }
    }
);

// on destroy, remove watcher
onBeforeUnmount(() => {
    watchCurrentDay();
});
</script>

<template>
    <TransitionGroup tag="div" name="fade" class="c-notifications flex flex-col gap-2">
        <template v-if="notifications.length > 0">
            <div v-for="(notification, index) in notifications" :key="index">
                <Notification
                    :theme="toCamelCase(getTheme(notification))"
                    :icon="toCamelCase(getIcon(notification))"
                    :text="getNotificationMessage(notification).desc"
                    :status="getNotificationMessage(notification).good"
                    @click="handleClick(notification)"
                />
            </div>
        </template>
    </TransitionGroup>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active,
.fade-move {
    --btn-opacity: 0;
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    transition-delay: calc(0.1s * var(--fade-delay));
}

.fade-enter-from,
.fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

.fade-leave-active {
    // position: absolute;
}
</style>
