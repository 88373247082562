<!--
    here is the default layout

    it has data for the background theme, screen theme and background image:
    - theme for background (default is 'primary')
    - theme for screen (default is 'primary')
    - background image (default is './assets/images/background/assembly/assembly_01.jpg')

    start script with setup attribute

-->

<!--
    template:
    add a div with class c-layout. Use tailwind class to set the dimension to full screen width and Height .

    add a screen component.
    add it a theme class with the theme prop as suffix (theme-<screenTheme>), use :class.
    add a background image with the image prop.
    it has a PageFrame component with a slotl

    add Background component.
    add it a theme class with the theme prop as suffix (theme-<backgroundTheme>).

    more style will be added later in the style tag
    -->

<script setup>
//We intercept the url before the app opens and redirect the user to the home page if the current page is something else.
//We are making this to provent errors of accessing game pages before a game has been started.
let url = window.location.pathname.toLocaleLowerCase();
if (url != '/' && url != '/en' && url != '/fr' && url != '/en/' && url != '/fr/') {
    let sub = url.substring(0, 3);
    if (sub == '/fr') {
        window.location.href = '/fr';
    } else {
        window.location.href = '/en';
    }
}

import RoboNationCore from '~/composables/RoboNationCore';
import useGameStore from '~/store/game';
import { AudioPlayer } from '~/composables/useAudio';

// data
const theme = ref('primary');
const screenTheme = ref('primary');
const bgImage = ref('/assets/images/background/assembly/assembly_01.jpg');
const isGameInProgress = ref(false);

// get router
const router = useRouter();
const { locale } = useI18n();
// add a method to update the theme of the page
const updateTheme = (newTheme) => {
    theme.value = newTheme;
    screenTheme.value = newTheme;
};

// add a method to update the background image of the page
const updateBgImage = (newBgImage) => {
    bgImage.value = newBgImage;
};

// add a method to update the game status
const updateGameStatus = () => {
    const playerID = useGameStore().playerID;
    isGameInProgress.value = true;
    RoboNationCore.instance.on(RoboNationCore.EVENT_CURRENT_EVENT_START, () => {
        if (
            RoboNationCore.instance.currentEvent &&
            Object.keys(RoboNationCore.instance.currentEvent.affectedPlayers).includes(playerID.toString())
        ) {
            RoboNationCore.instance.pause();
            useGameStore().isEvent.inProgress = true;
            useGameStore().isEvent.isUpdate = false;
            // redirect to event page
            router.push(`/${locale.value}/event`);
        }
    });
    RoboNationCore.instance.on(RoboNationCore.EVENT_CURRENT_EVENT_UPDATE, () => {
        if (
            RoboNationCore.instance.currentEvent &&
            Object.keys(RoboNationCore.instance.currentEvent.affectedPlayers).includes(playerID.toString())
        ) {
            RoboNationCore.instance.pause();
            useGameStore().isEvent.inProgress = true;
            useGameStore().isEvent.isUpdate = true;
            // redirect to event page
            router.push(`/${locale.value}/event?update=true`);
        }
    });
    RoboNationCore.instance.on(RoboNationCore.EVENT_CURRENT_EVENT_END, () => {
        useGameStore().isEvent.inProgress = false;
        useGameStore().isEvent.isUpdate = false;
    });
};

// add a method to set the game speed
const setGameSpeed = (speed) => {
    RoboNationCore.instance.setGameSpeed(speed);
};

const isTest = useTest();

AudioPlayer.stopAll();
</script>

<template>
    <v-app style="--v-theme-background: transparent">
        <div class="c-layout h-screen w-screen">
            <Screen :class="'theme-' + screenTheme" :image="bgImage">
                <div class="c-layout__notification absolute bottom-0 right-[-8rem] z-[60] m-4">
                    <Notifications />
                </div>
                <NuxtLayout>
                    <NuxtPage
                        @emit-page-theme="updateTheme"
                        @emit-bg-image="updateBgImage"
                        @emit-game-start="updateGameStatus"
                    />
                </NuxtLayout>
                <Modal />
            </Screen>
            <Background :theme="theme" />
        </div>
    </v-app>
</template>

<style lang="scss">
@import '@/assets/styles/main.scss';
</style>
