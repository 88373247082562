<!-- 
    Here is the Screen.vue component:
    Path: components/Screen.vue
    It is the container of the game.
    It receives one prop: image, a string.

    Build it as components/Notification.vue.
    start with the script tag with setup attribute
 -->
<script setup>
// add prop
const props = defineProps({
    image: {
        type: String,
        default: null,
    },
});

// add ref for frame image. It's value it path to assets/images/screen-frame.svg
const frameImage = ref('/assets/images/screen-frame.svg');

// add ref for current background image
const currentBgImage = ref(null);
// add ref for transitionBackgroundImage, set it to null
const transitionBackgroundImage = ref(null);
// add ref for isTransitioning, set it to false
const isTransitioning = ref(false);

// on mounted, set currentBgImage to image
onMounted(() => {
    currentBgImage.value = props.image;
    transitionBackgroundImage.value = props.image;
});

// as on Background component, add a transition on image prop
watch(
    () => props.image,
    () => {
        if (props.image !== null) {
            isTransitioning.value = true;
            transitionBackgroundImage.value = currentBgImage.value;
            currentBgImage.value = props.image;
        }
    }
);

// remove watcher on before unmount
onBeforeUnmount(() => {
    watch();
});

// end of script tag
</script>

<!-- 
    template tag
    add a div with class c-screen
    add a div with class c-screen__image
    add a img tag with src attribute and bind it to the image prop
    add a slot inside the div with class c-screen__content
-->

<template>
    <div class="c-screen relative">
        <div class="c-screen__image">
            <img :src="currentBgImage" />
            <!-- add a transition image here wrapped in reverse-fade-out transition 
                image should be in absolute position, top 0, left 0, width and height 100% (use tailwind)
            -->
            <Transition name="reverse-fade-out" @after-enter="isTransitioning = false">
                <img
                    v-if="isTransitioning"
                    class="absolute left-0 top-0 z-10 h-full w-full object-cover"
                    :src="transitionBackgroundImage"
                />
            </Transition>
        </div>

        <div class="c-screen__content z-10">
            <slot />
        </div>
        <!-- add c-screen__border div -->
        <div class="c-screen__frame relative">
            <!-- add image with src attribute assigned to frameImage -->
            <img :src="frameImage" />
        </div>
        <!-- add a div for bottom shadow -->
        <div class="c-screen__shadow"></div>
    </div>
</template>

<!-- 
    add empty style tag , language is scss , scoped
    add a class for the screen
    add a class for the image
    stach screen, image and slot with grid method
    set the image to 100% width and height
    set the image to be on top of the screen
    set the image to be centered
    set the image to be rounded
    start:
-->
<style lang="scss" scoped>
.c-screen {
    // add variable for border radius set to 40px (prefix with --screen)
    --screen-border-radius: 62px;
    // add variable for blur effect , set it to 2px (prefix with --screen)
    --screen-blur: 2px;

    // add variable for shadow width and height. set them to 120% and 20%
    --screen-shadow-width: 110%;
    --screen-shadow-height: 30%;
    // add variable for content padding, set it to 48px
    --screen-content-padding: 48px;
    --screen-height: 52rem;
    --screen-width: 84.5rem;

    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
    max-width: var(--screen-width);
    width: var(--screen-width);
    place-items: center;
    z-index: 0;
    color: var(--theme-color-500);
    height: var(--screen-height);
    max-height: var(--screen-height);

    border-radius: var(--screen-border-radius);

    & > * {
        grid-area: 1 / 1 / 2 / 2;
        // add aspect ratio 1351 / 831
        aspect-ratio: 1360 / 815;
        // add border radius
        border-radius: var(--screen-border-radius);
    }
}

.c-screen__image {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    object-fit: cover;
    // blur the screen
    filter: blur(var(--screen-blur));
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;

        // add border radius
        border-radius: var(--screen-border-radius);
    }
}

.c-screen__content {
    border-radius: 0;
    aspect-ratio: unset;
    max-height: 100%;
    overflow-y: auto;
    // add padding
    padding: var(--screen-content-padding);
    width: 100%;
    height: 100%;
    // hide overflow
    overflow: hidden;
    // center content horizontally
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-screen__frame {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // hide overflow
    //overflow: hidden;

    // add border-radius
    border-radius: var(--screen-border-radius);

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        // add border radius
        border-radius: var(--screen-border-radius);
        // add shadow
        box-shadow: 0 0 32px 6px rgba(0, 0, 0, 0.7);
    }
}

// add style for shadow. it's a the bottom of the screen
.c-screen__shadow {
    width: var(--screen-shadow-width);
    height: var(--screen-shadow-height);
    position: absolute;
    // position in bottom is 0 + middle of height
    bottom: calc(0px - var(--screen-shadow-height) / 2.25);
    // left position: shadow must be centered
    left: calc(50% - var(--screen-shadow-width) / 2);
    background: rgba(0, 0, 0, 0.75);
    filter: blur(58.5px);
    border-radius: 204px;
    z-index: -1;
}

// add a full modifier c-screen--full
.c-screen--full {
    // change content padding to 52px 142px
    --screen-content-padding: 52px 142px;
}
</style>
