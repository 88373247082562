<!-- 
    Here is the background component.

    for the script part, it receives a prop for the theme (default is 'null')

    start script with setup attribute
-->
<script setup>
// add props for the theme
const props = defineProps({
    theme: {
        type: String,
        default: null,
    },
});

// add a ref for currentTheme
const currentTheme = ref(null);
// add a ref for transitionBackgroundTheme, set it to null
const transitionBackgroundTheme = ref(null);
// add a ref for isTransitioning, set it to false
const isTransitioning = ref(false);

// on mounted, set currentTheme to theme
onMounted(() => {
    currentTheme.value = props.theme;
    transitionBackgroundTheme.value = props.theme;
});

// watch props.theme, if it is not null, each time it changes, set isTransitioning to true
watch(
    () => props.theme,
    () => {
        if (props.theme !== null) {
            isTransitioning.value = true;
            transitionBackgroundTheme.value = currentTheme.value;
            currentTheme.value = props.theme;
        }
    }
);

// remove watcher on before unmount
onBeforeUnmount(() => {
    watch();
});
</script>

<!-- 
    template:
    add a div with class c-background. Use tailwind class to set the dimension to full screen width and Height .
    add a theme class with the theme prop as suffix (theme-<theme>) if theme prop is not null !!!
    The background shows 6 spheres. Add a div for each sphere with class c-background__sphere and a modifier for each different sphere (1, 2, 3, 4, 5, 6). add rounded-full class to each sphere.
    There are absolute positioned.
    
    more style will be added later in the style tag
-->

<template>
    <div class="c-background h-screen w-screen" :class="'theme-' + currentTheme">
        <div class="c-background__sphere c-background__sphere--1 rounded-full"></div>
        <div class="c-background__sphere c-background__sphere--2 rounded-full"></div>
        <div class="c-background__sphere c-background__sphere--3 rounded-full"></div>
        <div class="c-background__sphere c-background__sphere--4 rounded-full"></div>
        <div class="c-background__sphere c-background__sphere--5 rounded-full"></div>
        <div class="c-background__sphere c-background__sphere--6 rounded-full"></div>
    </div>
    <!-- add a copy of c-background with the previous theme to make a fade transition between the both.
        this copy will be hidden when the transition is finished. it should be in the same position as the original background.
        if transitioning, at start of transition, this background start with currentTheme and end with opacity 0.
        in the same time the original background must take the theme from the props.

    -->
    <Transition name="reverse-fade-out" @after-enter="isTransitioning = false">
        <div
            v-if="isTransitioning"
            class="c-background z-10 h-screen w-screen"
            :class="'theme-' + transitionBackgroundTheme"
        >
            <div class="c-background__sphere c-background__sphere--1 rounded-full"></div>
            <div class="c-background__sphere c-background__sphere--2 rounded-full"></div>
            <div class="c-background__sphere c-background__sphere--3 rounded-full"></div>
            <div class="c-background__sphere c-background__sphere--4 rounded-full"></div>
            <div class="c-background__sphere c-background__sphere--5 rounded-full"></div>
            <div class="c-background__sphere c-background__sphere--6 rounded-full"></div>
        </div>
    </Transition>
</template>

<!-- 
    add style tag, scoped, lang set to scss
    add style for the background component
    add a style for the c-background class
    with variables for background gradient (add color start and color stop variables set them to --theme-color-900 and --theme-color-500), sphere size, sphere horizontal position , sphere vertical position.
    all variables are prefixed with --background-.

    never nest class in scss. Use BEM instead
 -->
<style scoped lang="scss">
.c-background {
    // add variables
    --background-color-start: var(--theme-color-700);
    --background-color-stop: var(--theme-color-900);
    --background-sphere-size: 100px;
    --background-sphere-horizontal-position: 50%;
    --background-sphere-vertical-position: 50%;
    // add variables for sphere gradient (start and stop color)
    --background-sphere-color-start: var(--theme-color-100);
    --background-sphere-color-stop: var(--theme-color-500);
    // add variables for sphere blur and rotation (set to 83px and 3.67deg)
    --background-sphere-blur: 83px;
    --background-sphere-rotation: 3.67deg;

    background-image: radial-gradient(
            circle at 100% 0%,
            var(--background-color-start) 0%,
            var(--background-color-stop) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

    // add blur effect
    filter: blur(8px);

    // hide overflow
    overflow: hidden;

    // set z-index to -1
    z-index: -1;

    // add scale effect to 1.1
    transform: scale(1.1);
    transform-origin: center center;
}

// add style for the sphere
.c-background__sphere {
    // add style for the sphere
    position: absolute;
    width: var(--background-sphere-size);
    height: var(--background-sphere-size);
    left: var(--background-sphere-horizontal-position);
    top: var(--background-sphere-vertical-position);
    background-image: radial-gradient(
        circle at 100% 0%,
        var(--background-sphere-color-start) 0%,
        var(--background-sphere-color-stop) 100%
    );
    // add blur effect
    filter: blur(var(--background-sphere-blur));
    // add rotation
    transform: rotate(var(--background-sphere-rotation));
}

// add random position and size for each sphere
.c-background__sphere--1 {
    --background-sphere-size: 500px;
    --background-sphere-horizontal-position: calc(var(--background-sphere-size) / -2);
    --background-sphere-vertical-position: calc(var(--background-sphere-size) / -2);
}

.c-background__sphere--2 {
    --background-sphere-size: 570px;
    --background-sphere-horizontal-position: 5%;
    --background-sphere-vertical-position: -10%;
    // set rotation to -133.37deg
    --background-sphere-rotation: -133.37deg;
}

.c-background__sphere--3 {
    --background-sphere-size: 930px;
    --background-sphere-horizontal-position: calc(100% - var(--background-sphere-size) / 2);
    --background-sphere-vertical-position: calc(var(--background-sphere-size) / -2);
    // set rotation to -50.14deg
    --background-sphere-rotation: -50.14deg;
}

.c-background__sphere--4 {
    --background-sphere-size: 930px;
    --background-sphere-horizontal-position: -15%;
    --background-sphere-vertical-position: 50%;
    // set rotation to 50.14deg
    --background-sphere-rotation: 50.14deg;
}

.c-background__sphere--5 {
    --background-sphere-size: 570px;
    --background-sphere-horizontal-position: 33%;
    --background-sphere-vertical-position: 33%;
    // set rotation to 50.14deg
    --background-sphere-rotation: 50.14deg;
}

.c-background__sphere--6 {
    --background-sphere-size: 360px;
    --background-sphere-horizontal-position: 95%;
    --background-sphere-vertical-position: 95%;
    // set rotation to 50.14deg
    --background-sphere-rotation: 50.14deg;
}

// end of style tag
</style>
