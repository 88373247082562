// https://vuetifyjs.com/en/features/global-configuration/
import { createVuetify, useDisplay } from 'vuetify';
import { VDataTable, VDataTableServer } from 'vuetify/labs/VDataTable';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import type { ThemeDefinition } from 'vuetify';
import { formatHex } from 'culori';
import type { I18n } from '@nuxtjs/i18n/dist/runtime/composables';
import '@mdi/font/css/materialdesignicons.min.css';
// Precompiled global CSS (reset, utilities, etc.), no component styles.
// Will be redirected to SASS if styles.configFile is set in vite or webpack.
import 'vuetify/styles';

// Import the JSON file with the tokens
import tokens from '../assets/tokens/tokens.json';

// Create Themes

// const primaryTheme: ThemeDefinition = {
//     colors: {
//         primary: formatHex(tokens.root.color.primary['500'].value),
//     },
// };

// const secondaryTheme: ThemeDefinition = {
//     colors: {
//         secondary: formatHex(tokens.root.color.secondary['500'].value),
//     },
// };

// const warehouseTheme: ThemeDefinition = {
//     colors: {
//         primary: formatHex(tokens.root.color.warehouse['500'].value),
//     },
// };

// const salesTheme: ThemeDefinition = {
//     colors: {
//         primary: formatHex(tokens.root.color.sales['500'].value),
//     },
// };

// const assemblyTheme: ThemeDefinition = {
//     colors: {
//         primary: formatHex(tokens.root.color.assembly['500'].value),
//     },
// };

// const marketStudyTheme: ThemeDefinition = {
//     colors: {
//         primary: formatHex(tokens.root.color.marketStudy['500'].value),
//     },
// };

// const humanResourcesTheme: ThemeDefinition = {
//     colors: {
//         primary: formatHex(tokens.root.color.humanResources['500'].value),
//     },
// };

export default defineNuxtPlugin((nuxtApp) => {
    const i18n = { global: nuxtApp.$i18n } as I18n;

    // Get the breakpoints from the tokens
    const breakpoints: { [key: string]: number } = {};

    Object.entries(tokens.root.breakpoint).forEach(([key, val]) => {
        breakpoints[key] = parseInt(val.value.replace('px', ''));
    });

    const vuetify = createVuetify({
        display: {
            thresholds: {
                ...breakpoints,
            },
        },
        defaults: {
            global: {
                ripple: false,
            },
            // VBtn: {
            //     variant: 'flat',
            //     color: 'primary',
            //     variants: {
            //         outlined: {
            //             css: {
            //                 border: 'solid 2px var(--gradient-primary)',
            //             },
            //         },
            //     },
            // },
        },
        // theme: {
        //     defaultTheme: 'primaryTheme',
        //     themes: {
        //         primaryTheme,
        //         // secondaryTheme,
        //         // warehouseTheme,
        //         // salesTheme,
        //         // assemblyTheme,
        //         // marketStudyTheme,
        //         // humanResourcesTheme
        //     },
        // },
        components: {
            VDataTable,
            VDataTableServer,
        },
        locale: {
            adapter: createVueI18nAdapter({ i18n, useI18n }),
        },
    });

    nuxtApp.vueApp.use(vuetify);
});
