<script setup lang="ts">
import { ref, watchEffect } from 'vue';

interface GrIconProps {
    name: string;
    filled?: boolean;
}

const props = defineProps({
  "name": null,
  "filled": { type: Boolean, default: false }
});

const icon = ref('');

watchEffect(async () => {
    try {
        const iconsImport = import.meta.glob('assets/icons/**/**.svg', { as: 'raw', eager: false });
        const rawIcon = await iconsImport[`/assets/icons/${props.name}.svg`]();
        icon.value = rawIcon;
    } catch {
        // eslint-disable-next-line no-console
        console.error(`[BaseIcon] Icon '${props.name}' doesn't exist in 'assets/icons'`);
    }
});
</script>

<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-if="icon" class="base-icon" :class="{ 'base-icon--fill': filled }" v-html="icon" />
</template>

<style>
/* 
class .base-icon 
- display flex with center content
*/
.base-icon {
    display: flex;
    align-items: center;
}

.base-icon svg {
    width: 100%;
    height: 100%;
}

.base-icon--fill * {
    fill: currentColor;
}
</style>
