<!--
    Button component file.
    technologie is vue.js v3 (composition api) with nuxt v3
    project use tailwind v3 and vuetify v3.

    Button allow the player to interact with the game. Mostly to launch an action or to navigate trough the game.

    The style of the button follow the main style of the game.
    - border is gradient colored and rounded.
    - background is gradient colored and has opacity and blur effect (it's glassmorphism effect to simulate a frosted glass).
    - on hover, a blurred shadow is added to the button.

    the file start with a script tag with the setup attribute
    langage is javascript
    no typescript
-->
<script setup>
// import useUniqueId function from '@/composables/useUniqueId'
import { useUniqueId } from '@/composables/useUniqueId';
import { AudioPlayer } from '@/composables/useAudio';

/*
    props:
    - label
    - icon
    - status
    - action (function) or link (string)
    - variant (string)

    status can be: disabled, loading, default, active

    don't import 'defineProps' with nuxt
*/
const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    icon: {
        type: String,
        required: false,
        default: null,
    },
    iconOrientation: {
        type: String,
        required: false,
        default: 'right',
    },
    status: {
        type: String,
        required: false,
        default: 'default',
    },
    // action: {
    //     type: Function,
    //     required: false,
    //     default: null,
    // },
    link: {
        type: String,
        required: false,
        default: null,
    },
    // add variant prop, default value is c-btn--ghost
    variant: {
        type: String,
        required: false,
        default: 'c-btn--ghost',
    },
    // add theme prop, default value is null
    theme: {
        type: String,
        required: false,
        default: null,
    },
    // add progression prop, default value is null
    progression: {
        type: Number,
        required: false,
        default: null,
    },
});

// add a computed for icon
const icon = computed(() => {
    if (!props.icon) {
        return null;
    }

    return props.icon;
});

// icon will become a dynamic component
// capitalize icon name
// add prefix 'Svg' to icon name

// add computed property for icon
// if icon is null, return null
// else return 'Svg' + icon.charAt(0).toUpperCase() + icon.slice(1)

const SvgIcon = computed(() => {
    if (!icon) {
        return null;
    }

    return 'Svg' + icon.value.charAt(0).toUpperCase() + icon.value.slice(1);
});

//Add a method to listen for clicks
const onSelfClick = () => {
    AudioPlayer.playSfx("buttonclick");
};

</script>
<!--
    component use v-btn from vuetify v3.
    Style is custom and defined in style tag (with scss).
    give template for base button that will be customized by css.
    don't use vuetify style.
    Don't use classes from tailwind,

    add class theme like this: :class="theme-{{theme}}"
    Don't duplicate class prop.
    class prop has variant and class theme in array.
    add class c-btn


 -->
<template>
    <v-btn
        :class="['c-btn', variant, 'theme-' + theme, { 'c-btn--active': status === 'active' }]"
        :disabled="status === 'disabled'"
        :loading="status === 'loading'"
        :icon="icon"
        :link="link"
        :theme="theme"
        :onClick="onSelfClick"
    >

        <component :is="SvgIcon" v-if="icon && iconOrientation !== 'right'" :class="['c-btn__icon']" :identifiant="useUniqueId()" />

        <!--
            class is c-btn__label
            add span for c-btn label if label != null
            if variant has 'c-btn--ghost', add class u-gradient-text
            z-index is 10 (use tailwind class z-10)
        -->
        <span v-if="label" :class="['c-btn__label', variant.includes('c-btn--ghost') ? 'u-gradient-text' : '', 'z-10']">
            {{ label }}
        </span>

        <!--
            if icon != null,
            add a dynamic components with icon name and with path = "components/svg/{{ icon }}.svg"
        -->
        <component :is="SvgIcon" v-if="icon && iconOrientation === 'right'" :class="['c-btn__icon']" :identifiant="useUniqueId()" />

        <!--
            add span for c-btn background
            class is c-btn__bg
            element is hide if variant != c-btn--ghost (use tailwind class)
        -->
        <span :class="['c-btn__bg', variant !== 'c-btn--ghost' ? 'hidden' : '']"></span>

        <!--
            add span for c-btn border
            if variant contains string c-btn--ghost, add class u-gradient-border else display none (use tailwind class)
            if variant contains string c-btn--small, hide the element (use tailwind class)
        -->

        <span
            :class="[
                'c-btn__border',
                variant.includes('c-btn--ghost') ? 'u-gradient-border' : 'hidden',
                variant.includes('c-btn--small') ? 'hidden' : '',
            ]"
        ></span>

        <!--
            span for box shadow
         -->
        <span class="c-btn__shadow"></span>
        <!-- span for progression -->
        <span
            v-if="progression !== null"
            class="c-btn__progression"
            :style="{ '--btn-progression-width': progression + '%' }"
        ></span>
    </v-btn>
</template>

<!-- style begin here -->
<style lang="scss" scoped>
/* import utilities.scss from assets/styles/workspace */
@import './assets/styles/workspace/utilities.scss';

/*
    Don't use sass variables but css variables.
    for color use --theme-color-<index> variable defined in themes.scss
    for other css properties use variables defined in tokens.scss

    first button is .c-btn
    background:
    - background color is --theme-gradient (use background image for gradient)

    text:
    - font family is primary (variable is --font-family-primary)
    - font size is 200
    - font weight is medium (use value 500 directly)
    - text color is index 900

    spacing (use value directly):
    - horizontal padding is 32px
    - vertical padding is 16px

    border:
    - border radius is 16px

    create css variables for each property.
    variable has --btn prefix
    assign this variable to css property

    */
.c-btn {
    // variables
    --btn-background: var(--theme-gradient);
    --btn-text-color: var(--theme-color-0);
    --btn-font-family: var(--font-family-primary);
    --btn-border-radius: 16px;
    --btn-text-color: var(--theme-color-900);
    // no shadow
    --btn-box-shadow: none;
    // background color is transparent
    --btn-background-color: transparent;
    // add margin left 16px for icon
    --btn-gap: 16px;

    // add width and height for icon. set to auto
    --btn-icon-width: auto;
    --btn-icon-height: auto;
    // set variable u-border-radius of u-gradient-border to 16px
    --u-border-radius: 16px;

    // add start and stop color for icon gradient (--btn-icon-color-start and --btn-icon-color-stop)
    // start color is index 900, stop color is index 900
    // don't prefix with --btn
    --btn-icon-color-start: var(--theme-color-900);
    --btn-icon-color-stop: var(--theme-color-900);

    /*
        c-btn__shadow variables
        - opacity is 0
        - box-shadow is 0px 0px 15px with color index 400
    */
    --btn-shadow-opacity: 0;
    --btn-shadow-box-shadow: 0px 0px 15px var(--theme-color-400);

    // add variable for progression
    --btn-progression-width: 0%;
    --btn-progression-background-color: var(--theme-gradient);
    --btn-progression-opacity: 0.25;
    // add a variable for progression animation
    --btn-progression-time: 0.17s;
    --btn-text-transform: none;

    // add variable for btn opacity (used for disabled state)
    --btn-opacity: initial;

    // set vuetify variables to auto
    --v-btn-font-size: auto;
    --v-btn-font-weight: auto;
    --v-btn-horizontal-padding: auto;
    --v-btn-vertical-padding: auto;
    --v-btn-border-radius: auto;
    // --v-btn-height to auto
    --v-btn-height: auto;

    //reset vuetify style
    // text-transform
    text-transform: var(--btn-text-transform);
    letter-spacing: normal;

    // css properties
    // use background-image for gradient
    background-image: var(--btn-background);
    // use css variables for other properties
    color: var(--btn-text-color);
    font-family: var(--btn-font-family);
    font-size: var(--btn-font-size);
    font-weight: var(--btn-font-weight);
    padding: var(--btn-vertical-padding) var(--btn-horizontal-padding);
    border-radius: var(--btn-border-radius);
    box-shadow: var(--btn-box-shadow);
    background-color: var(--btn-background-color);
    // assign width and height to css variables
    width: var(--btn-width);
    height: var(--btn-height);

    :deep(.v-btn__content)  {
        gap: var(--btn-gap);
    }

    // on hover only add shadow property to c-btn__shadow
    &:hover {
        // css properties
        --btn-shadow-opacity: 1;
    }

    .c-btn__shadow {
        // css properties
        // position is absolute
        position: absolute;
        // top, left, right, bottom is 0
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // border radius is 16px
        border-radius: var(--btn-border-radius);
        opacity: var(--btn-shadow-opacity);
        box-shadow: var(--btn-shadow-box-shadow);
        // add transition fast on opacity
        transition: opacity var(--transition-fast);
    }

    // if btn is disabled, set opacity to 0.33
    &:disabled {
        // css properties
        // set opacity
        opacity: var(--btn-opacity);
        --btn-opacity: 0.33;
    }

    //end of .c-btn
}

// add z-index 10 for icon
.c-btn__icon {
    z-index: 10;
    // add width and height to css variables
    width: var(--btn-icon-width);
    height: var(--btn-icon-height);
}

// modifier --ghost
.c-btn--ghost {
    --btn-bg-background: var(--theme-gradient);
    --btn-bg-position: absolute;
    --btn-bg-top: 0;
    --btn-bg-left: 0;
    --btn-bg-right: 0;
    --btn-bg-bottom: 0;
    --btn-bg-border-radius: var(--btn-border-radius);
    --btn-bg-opacity: 0.3;
    --icon-color-start: var(--theme-color-100);
    --icon-color-stop: var(--theme-color-500);
    --icon-blur-filter: 20px;

    /*
        background:
        - background color is transparent
        - background image is none
    */
    --btn-background: transparent;
    // position is relative
    position: relative;
    // add backdrop filter
    backdrop-filter: blur(var(--icon-blur-filter));

    /*
        add css variables for c-btn__bg
        - background color is --theme-gradient
        - position is absolute
        - top, left, right, bottom are 0
        - border radius use css variable from c-btn
        - opacity is .3
    */
    .c-btn__bg {
        // css properties
        background: var(--btn-bg-background);
        position: var(--btn-bg-position);
        top: var(--btn-bg-top);
        left: var(--btn-bg-left);
        right: var(--btn-bg-right);
        bottom: var(--btn-bg-bottom);
        border-radius: var(--u-border-radius);
        opacity: var(--btn-bg-opacity);
        // display block
        display: block;

        // fast transtion on opacity (use --transition-fast variable)
        transition: opacity var(--transition-fast);

        /*
         add a pseudo element before
         this element will add a dark and transparent background for a better reading of the text
         */

        // &::before {
        //     // css properties
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     // background color is black
        //     background-color: var(--theme-color-900);
        //     // border radius is 16px
        //     border-radius: var(--btn-border-radius);
        //     // add a blur effect
        //     backdrop-filter: blur(var(--screen-blur));
        //     // put the element behind the other element
        //     z-index: -1;
        // }
    }

    /* c-btn__border
        - zindex is 1
    */
    .c-btn__border {
        --btn-border-z-index: 1;
        z-index: var(--btn-border-z-index);
        // position is absolute
        position: absolute;
        // top, left, right, bottom is 0
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    // c-btn__progression
    // absolute position with full width and height
    // add a transform property to move the progression
    // set the transform origin to left
    // set transform to scaleX , and set it css variable --btn-progression-width
    .c-btn__progression {
        // css properties
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--u-border-radius);
        overflow: hidden;

        &::before {
            // css properties
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform-origin: left;
            transform: scaleX(var(--btn-progression-width));
            // transition on transform
            transition: transform var(--btn-progression-time) linear;
            background-image: var(--btn-progression-background-color);
            opacity: var(--btn-progression-opacity);
        }

        &:hover {
            --btn-bg-opacity: 0.75;
        }
    }

    /*
     on hover button or if the button is wrapped with tag with class '.router-link-active' or '.link--active' look the same as c-btn

     - btn-bg-opacity is 1
     - text color is index 900 (override u-gradient-text property)
     - no border (hide c-btn__border)
        */
    &:hover,
    .router-link-active &,
    .link--active &,
    &.c-btn--active {
        // css properties
        --btn-bg-opacity: 1;
        --btn-text-color: var(--theme-color-900);
        --btn-border-color: transparent;
        --icon-color-start: var(--theme-color-900);
        --icon-color-stop: var(--theme-color-900);

        // override u-gradient-text property on c-btn__label
        .c-btn__label {
            // cancel background and background-clip property
            background: none;
            background-clip: border-box;
            -webkit-text-fill-color: var(--btn-text-color);

            // end of .c-btn__label
        }

        // end of .c-btn--ghost
    }
}

/*
 modifier c-btn--square.
 button is square with height and width is 64px.
 Padding is 8px.
 Content is only svg icon (c-btn__icon)
 it occupied all space inside button.
 change the minimun variables of c-btn
*/
.c-btn--square {
    --btn-height: var(--btn-square-height);
    --btn-width: var(--btn-height);
    --btn-horizontal-padding: 10px;
    --btn-vertical-padding: 10px;
    // set icon width and height to --btn-height - --btn-vertical-padding * 2
    --btn-icon-width: calc(var(--btn-height) - var(--btn-vertical-padding) * 2);
    --btn-icon-height: calc(var(--btn-height) - var(--btn-vertical-padding) * 2);
}

/*
    add a modifier to c-btn--full-width.
    it set the width variable to 100%
*/
.c-btn--full-width {
    --btn-width: 100%;
}

/*
 add a modifier for small button.
 - height is 36px,
 - font-size is 14px,
 - border-width is 1px solid and theme-color-500
 - border-radius is 4px
 - padding is 6px;
 - width is 72px;
 - change btn background color to theme-color-500

 use only css variables
*/
.c-btn--small {
    --btn-height: 36px;
    --btn-font-size: 14px;
    --btn-border-width: 1px;
    --btn-border-color: var(--theme-color-500);
    --btn-border-radius: 4px;
    --u-border-radius: var(--btn-border-radius);
    --btn-horizontal-padding: 6px;
    --btn-vertical-padding: 6px;
    --btn-width: 72px;
    --btn-bg-background: var(--theme-color-500);

    border: var(--btn-border-width) solid var(--btn-border-color);
}

/*
    add a modifier for long button.
    - width is 100%
    - height is 32px
*/
.c-btn--long {
    --btn-width: 100%;
    --btn-height: 32px;
    // change verticlal padding to 6px
    --btn-vertical-padding: 4px;
    // change icon size to 24px x 24px
    --btn-icon-width: 24px;
    --btn-icon-height: 24px;
    // change border radius to 10px
    --u-border-radius: 10px;
}

// end of style
</style>
