/**
 * fakeData.js supplies fake data for testing purposes.
 * it will also check if testing mode is enabled by checking the state of the test variable (useTest)
 */

import { useTest } from './state.js';

// add a method isTesting() that the state of useTest() to determine if testing mode is enabled
// return a boolean
export const isTesting = () => {
    const test = useTest().value;
    return test;
};

/*

    add a method getFakeData() that returns an array of fake data
    first fake data is signed agreements. the structure of the data is:
     *                          {
     *                              [contractId]: {
     *                                 startDate: The date the agreement was signed
     *                                 duration: The duration of the agreement in days
     *                                 cycleDone: The amount of cycles done so far
     *                                 monthLeft: [agreementMonthLeft],//--[(startDate + duration) - currentDate] / this.daysPerMonth--//
     *                                 antiSteal: If the antiSteal clause was signed or not
     *                                 governing: If the agreement is a governing one or not
     *                                 stealCost: The cost to steal the agreement,
     *                                 content: {
     *                                     [robotId]: {
     *                                         amount: The quantity of the robot to sell per cycle
     *                                         price: The unit price of the robot to sell per cycle
     *                                         category: {
     *                                              category: The category of the robot,
     *                                              rarity: The rarity of the robot
     *                                          }
     *                                      }    
     *                                 }
     *                              }
     *                          }
    
*/
export const getFakeData = () => {
    return {
        signedAgreements: {
            12345: {
                startDate: new Date(2023, 8, 8),
                duration: 365,
                cycleDone: 2,
                monthLeft: 12,
                antiSteal: false,
                governing: false,
                stealCost: 123456,
                content: {
                    1: {
                        amount: 1,
                        price: 1500,
                        category: {
                            category: 2,
                            rarity: 2,
                        },
                    },
                    // add another one
                    3: {
                        amount: 6,
                        price: 2000,
                        category: {
                            category: 5,
                            rarity: 1,
                        },
                    },
                },
            },
            // add another one
            12346: {
                startDate: new Date(2023, 8, 8),
                duration: 365,
                cycleDone: 2,
                monthLeft: 1,
                antiSteal: true,
                governing: true,
                stealCost: 123456,
                content: {
                    2: {
                        amount: 3,
                        price: 1650,
                        category: {
                            category: 3,
                            rarity: 3,
                        },
                    },
                    // add another one
                    4: {
                        amount: 8,
                        price: 3000,
                        category: {
                            category: 4,
                            rarity: 1,
                        },
                    },
                    // add another one
                    5: {
                        amount: 3,
                        price: 2800,
                        category: {
                            category: 3,
                            rarity: 2,
                        },
                    },
                },
            },
        },
        // add a method that return the lenght of the signedAgreements object
        getSignedAgreementsCount: function () {
            return Object.keys(this.signedAgreements).length;
        },
        /* 
            add fake data for the robots research and development
            there is tree types of research tree:
                - 1: The research tree for the robots
                - 2: The research tree for technology
                - 3: The research tree for staff training
            
            each tree is identified by is number (1, 2 or 3)
            it is used as key for the research tree object
            each key as a object with:
                - totalProgression: a value in percentage that represent the total progression of the research tree
                - currentResearch: the current research that is being done(it's an integer between 1 and 16 for robots, 17 and 21 for technology and 22 and 26 for staff training), 
                - currentResearchProgression: the progression of the current research in percentage

            put different and random (dont repeat yourself tete de noeud) value for every key to test the progression of the research tree

            for each, add :
            timeLeft: duration in seconds
            totalTime: duration in seconds
        */
        researchTree: {
            1: {
                totalProgression: 0.56,
                ongoingResearch: 1,
                currentProgress: 0.32,
                timeLeft: 1000,
                totalTime: 2000,
            },
            2: {
                // don't repeat the same value like an idiot
                totalProgression: 0.78,
                ongoingResearch: 0,
                currentProgress: 0,
                timeLeft: 0,
                totalTime: 0,
            },
            3: {
                totalProgression: 0.12,
                ongoingResearch: 22,
                currentProgress: 0.78,
                timeLeft: 3600,
                totalTime: 5800,
            },
        },

        researchInProgress: {
            id: '1',
            timeLeft: 26.55,
            duration: 120,
        },
        /* add fake notifications data.
         an array with different notifications object like this:
              *                          {
     *                              id: The identifier of the notification,
     *                              groupId: The identifier of the group the notification belongs to (from the NOTIFICATION_GROUP enum),
     *                              date: In-game date when the notificaiton was triggered,
     *                              values: [
     *                                  [value1],
     *                                  [value2],
     *                                  [value3]
     *                              ]
     *                          }
        */

        notifications: [
            {
                id: 1,
                groupId: 1,
                date: 100,
                values: ['0', '0', '0'],
            },
            {
                id: 2,
                groupId: 2,
                date: 50,
                values: ['0', '0', '0'],
            },
            {
                id: 3,
                groupId: 3,
                date: 350,
                values: ['0', '0', '0'],
            },
            {
                id: 4,
                groupId: 4,
                date: 450,
                values: ['0', '0', '0'],
            },
            {
                id: 5,
                groupId: 5,
                date: 550,
                values: ['0', '0', '0'],
            },
            {
                id: 6,
                groupId: 6,
                date: 650,
                values: ['0', '0', '0'],
            },
            {
                id: 7,
                groupId: 7,
                date: 750,
                values: ['0', '0', '0'],
            },
            {
                id: 8,
                groupId: 8,
                date: 850,
                values: ['0', '0', '0'],
            },
            {
                id: 0,
                groupId: 0,
                date: 160,
                values: ['0', '0', '0'],
            },
        ],
    };
};
