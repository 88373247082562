<!-- 
    Here is the notification Component.
    It will show to the player different information about the activity for different departments.
    (Each department has its own theme).

    To help the player to identify the department, the notification is colored by the theme class (theme-<theme>).
    It also has an icon representing the department.

    The notification bring different informations regarding departements (or game status) to the player.

    Notification is a card. Its style is closed to the button component.
    - same border, background and shadow.

    the file starts with the script tag with the setup attribute.
    langage is javascript only. 
    No typescript, PLEASE !!!!!
    you don't need to import defineProps, defineEmits, defineExpose, withDefaults.
    you don't need to precise that lang = 'js' because it's the default value.
 -->
<script setup>
// you don't need to import defineProps, defineEmits, defineExpose, withDefaults.
// this componenent need 4 props:
// - icon: the icon name
// - theme: the theme name
// - text: the text to display
// - status: the status of the notification
import RoboNationCore from '~/composables/RoboNationCore';
import useGameStore from '~/store/game';

const { playerID } = useGameStore();

// start here to code here:
defineProps({
    icon: {
        type: String,
        default: '',
    },
    theme: {
        type: String,
        default: '',
    },
    text: {
        type: String,
        default: '',
    },
    status: {
        type: Boolean,
        default: false,
    },
    event: {
        type: Object,
        default: null,
    },
});

const { t } = useI18n();

const getEffectText = (effect) => {
    return RoboNationCore.instance.getTranslatedModifierDesc(effect, (key) => t(key));
};

// end of the script tag
</script>

<!-- template tag -->
<template>
    <!-- 
        Component template
        component use v-card from vuetify v3.0.0. 

        First, create a main div for layout. 
        Notification is a little card that will be displayed on the right side of the screen.
        It has 2 columns: left one is the icon, right one is the text.
        the 2 columns are separated by a fine vertical line.

        Put only one main div for layout (v-card tag). Add class theme if theme is not empty.
        Inside put only 3 divs for icon, vertical line and text. that's all !!!
        (border, background and shadow will be added later !!!)

        style will be done in the style tag.

        open with v-card tag. add class c-notification.

     -->
    <v-card class="c-notification" :class="`theme-${theme}`">
        <!-- 
            to prevent default style from v-card, wrap the card with a div with class c-notification__layout.
            add a z-index to be sure that the notification will be displayed on top of the other components.
         -->
        <div class="c-notification__layout z-10" :class="[icon ? 'grid' : 'block']">
            <!-- 
            add 3 divs for icon, vertical line and text.
            icon div is the first one.
            add class notification__icon.
            add a component GrIcon with icon name.
        -->
            <div v-if="icon" class="c-notification__icon">
                <GrIcon :name="icon" />
            </div>
            <!--
            add a div for the vertical line.
        -->
            <div v-if="icon" class="c-notification__line" />
            <!--
            add a div for the text.
            add class c-notification__text.
            add the text.

            Use tailwind for this div.
            As general background is dark, text is white.
            Use primary font, size is base.
        -->
            <div class="c-notification__text font-primary text-base" style="color: var(--theme-color-100)">
                <div
                    v-if="event !== null"
                    class="flex flex-col gap-2 text-140 text-primary-500"
                    :class="[event.content.outcome === 0 ? 'text-important-bad' : 'text-important-good']"
                >
                    <div
                        class="font-bold uppercase tracking-[2.8px]"
                        :class="[event.content.outcome ? 'text-sales-500' : 'text-secondary-500']"
                        v-html="
                            RoboNationCore.instance.getTranslatedEvent(event.content, event.isUpdate, (key) => t(key))
                                .title
                        "
                    />
                    <div
                        v-html="
                            RoboNationCore.instance.getTranslatedEvent(event.content, event.isUpdate, (key) => t(key))
                                .description
                        "
                    />
                    <div>
                        <div
                            v-for="(effect, effectIndex) in event.content.affectedPlayers[playerID.toString()]
                                .modifiers"
                            :key="effectIndex"
                            class="font-bold"
                        >
                            <span>{{ t('event.effects') }}: </span>
                            <span v-html="getEffectText(effect)" />
                        </div>
                    </div>
                    <div class="font-bold">
                        {{ t('global.duration') }}:
                        <span v-if="event.content.date.end > 0"
                            >{{ t('global.until') }} {{ formatDate(event.content.date.end) }}</span
                        >
                        <span v-else>{{ t('event.restOfGame') }} </span>
                    </div>
                </div>
                <template v-else>
                    {{ text }}
                </template>
            </div>
        </div>
        <!-- 
            background:
            to keep constistency with the button component, add a span with class c-notification__background.
            background is gradient colored. you can use the utility class .u-gradient-bg.
            use tailwindcss to made it absolute and full width.
         -->
        <span
            class="c-notification__background u-gradient-bg absolute inset-0"
            :style="!status ? `--theme-gradient: var(--gradient-secondary)` : ''"
        />
        <!-- 
            border:
            to keep constistency with the button component, add a span with class c-notification__border.
            border is gradient colored. you can use the utility class .u-gradient-border.
            use tailwindcss to made it absolute and full width.

            as border cover the whole card and card has a border, border must have a border radius. 
            border is defined by u-gradient-border class and have a css variable to define border radius value (--u-border-radius)
            use css variables for border radius. define it in style attribute.
         -->
        <span
            class="c-notification__border u-gradient-border absolute inset-0"
            style="--u-border-radius: var(--notification-border-radius)"
        />

        <!-- 
            shadow:
            to keep constistency with the button component, add a span with class c-notification__shadow.
            Shadow opacity is 0.
            When notification is hovered, shadow opacity is 1.
            use tailwindcss to made it absolute and full width.
         -->
        <span class="c-notification__shadow absolute inset-0" />
    </v-card>
</template>

<!-- style tag, langage is scss -->
<style lang="scss" scoped>
/*
    Notification is a small card that will be displayed on the right side of the screen.
    it is a little rectangle with an icon and a text.
    start with .c-notification class.
    use css variables for properties that can be changed.
    every variables are in .c-notification class.
*/
.c-notification {
    // as the style is custom, you have to reset the default style of v-card.
    // reset margin, padding, border, background and shadow.
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    box-shadow: none;
    position: relative;
    // reset overflow to visible.
    overflow: visible;
    // v-card is a block element by default but should not occupy the whole width.
    // use inline-block to keep the width of the card.
    display: inline-block;

    // define css variables you need for dimensions, flex gap, border radius.
    // width is 348px.
    // min height is 96px but it can grow if text is too long.
    // padding is 16px.
    // gap between elements is 16px.
    // border radius is the same as button component (Button.vue, 16px).
    // use css variables for border radius.
    // each variable is prefixed by --notification-.
    // using css varaibles will help you to change the style easily.
    --notification-width: 348px;
    --notification-min-height: 96px;
    --notification-padding: 16px;
    --notification-gap: 16px;
    --notification-border-radius: 16px;

    // icon:
    // icon must be visible. Its container width 42px.
    // use css variables for icon width with --notification prefix
    --notification-icon-width: 42px;

    // line:
    // line is a vertical line that separate icon and text.
    // line is 1px width.
    // use css variables for line width with --notification prefix
    --notification-line-width: 1px;

    // to color the icon, you should use icon css variables as definded in most of svg from the folder assets/icons.
    // to be consistent with the general theme, icon is gradient colored, and use theme colors.
    // icon has a start and stop color. Start color is theme color with index 100, stop color is theme color with index 500.
    // assign icon variables directly here
    --icon-color-start: var(--theme-color-100);
    --icon-color-stop: var(--theme-color-500);

    // to be consistent, set the --u-border-radius variable to the same value as --notification-border-radius
    --u-border-radius: var(--notification-border-radius);

    // on hover, shadow opacity is 1.
    // use css variables for shadow opacity with --notification prefix
    // set shadow opacity to 0 by default
    --notification-shadow-opacity: 0;
    // shadow is blurred and as the same value as button
    // use css variables for shadow blur with --notification prefix
    --notification-shadow-blur: 16px;

    // assign width and height property
    width: var(--notification-width);
    min-height: var(--notification-min-height);

    // on hover, shadow opacity is 1.

    // to inform user that notification is clickable, cursor is pointer.
    cursor: pointer;

    transition: transform 0.2s ease-out;

    // add hover comportement here
    &:hover {
        // on hover, shadow opacity is 1.
        --notification-shadow-opacity: 1;
        transform: translateX(-1rem);
    }

    &::before {
        content: '';
        border-radius: var(--u-border-radius);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(17.5px);
        z-index: -1;
    }
}

// layout class
.c-notification__layout {
    // layout:
    // every elements are displayed in a row.
    // icon and text are centered vertically.
    // use grid layout to avoid using flexbox. element inside flexbox don't have the same height.
    // use css variables for gap between elements.
    grid-template-columns: var(--notification-icon-width) var(--notification-line-width) 1fr;
    align-items: center;
    gap: var(--notification-gap);
    // padding is defined by css variable --notification-padding
    padding: var(--notification-padding);
    // elements are centered horizontally. use the correct grid property
    justify-items: center;
    // assign min-height variable. height is the same as min-height
    min-height: var(--notification-min-height);
}

// icon class
.c-notification__icon {
    // icon width
    width: var(--notification-icon-width);
    // add a min width to be sure that icon will be visible.
    min-width: var(--notification-icon-width);
    // it's verticaly centered, use grid property
    align-self: center;
}

// line class
// line is a vertical line that separate icon and text.
// background color is gradient that use theme colors, you can use css variables already existing in themes.scss
// line width is defined by css variable --notification-line-width
.c-notification__line {
    // width
    width: var(--notification-line-width);
    // height
    height: 100%;
    // background
    background: var(--theme-gradient);
}

// shadow class
// shadow effect is used to make notification more visible.
// it give impression of elevation.
// use box-shadow property.
// it's theme colored with index 500. use css variables for blur.
// shadow opacity is defined by css variable --notification-shadow-opacity
.c-notification__shadow {
    // shadow opacity
    opacity: var(--notification-shadow-opacity);
    // shadow blur
    box-shadow: 0 0 var(--notification-shadow-blur) var(--theme-color-500);
    // as it's absolute positionned and cover the whole card, add border-radius (use css variable defined in .notification class)
    // this one start with --u- prefix
    border-radius: var(--u-border-radius);

    // add a transition on shadow opacity.
    // transition property is opacity.
    // transition duration is 0.3s.
    // transition timing function is ease-in-out.
    transition: opacity 0.3s ease-in-out;
}

.c-notification__background {
    border-radius: var(--u-border-radius);
    overflow: hidden;
}
</style>
