/*
    This composable is a list of functions that can be used to play audio files using the HowlerJS library.

    The AudioPlayer class can be imported from any component and contains the following static functions:
    - {preloadAudio}: This function will preload all the audio files in our AudioLibrary object.
    - {changeVolume}: This function will update the value of the volume
    - {playBgm}: This function will play a background music (bgm) audio file
    - {stopBgm}: This function will stop the current background music (bgm) audio file
    - {playSfx}: This function will play a background music (sfx) audio file

    The class also contains a static property to be called where the list of audio files are stored:
    - {library}: This object contains a list of all the audio files we want to use in our app.

*/
import {Howl, Howler} from 'howler';
// import useSettingsStore from store
import useSettingsStore from '~/store/settings';

/*
-------------------------------------------
    AUDIO LIBRARY
-------------------------------------------
*/
export class AudioPlayer {

    static currentBgm = null;
    static currentSfxs = [];
    static nextAudioId = 0;

    static library = {
        'bgm': {
            'ingame': '/assets/audio/bgm/ingame.mp3',
            'menu': '/assets/audio/bgm/menu.mp3'
        },
        'sfx': {
            'assembly': '/assets/audio/sfx/assembly.mp3',
            'buttonclick': '/assets/audio/sfx/buttonclick.mp3',
            'drag': '/assets/audio/sfx/drag.mp3',
            'drop': '/assets/audio/sfx/drop.mp3',
            'eventbad': '/assets/audio/sfx/eventbad.mp3',
            'eventgood': '/assets/audio/sfx/eventgood.mp3',
            'hr': '/assets/audio/sfx/hr.mp3',
            'marketstudy': '/assets/audio/sfx/marketstudy.mp3',
            'notification': '/assets/audio/sfx/notification.mp3',
            'research': '/assets/audio/sfx/research.mp3',
            'sales': '/assets/audio/sfx/sales.mp3',
            'sell': '/assets/audio/sfx/sell.mp3',
            'warehouse': '/assets/audio/sfx/warehouse.mp3'
        }  
    };

    /*
    -------------------------------------------
        PRELOAD & SETTINGS FUNCTIONS
    -------------------------------------------
    */
    /**
    *   Preloads all the audio files in our library object and adds them to the head of our document.
    *   @param {useHead} function that adds a link tag to the head of our document.
    */
    static preloadAudio(useHead) {
        //We loop through the library and with the useHead function we add a link tag to the head of our document.
        let toExport = [];
        for(let key in AudioPlayer.library) {
            for(let subKey in AudioPlayer.library[key]) {
                toExport.push({
                    rel: 'preload',
                    href: AudioPlayer.library[key][subKey],
                    as: 'audio',
                });
            }
        }

        useHead({
            'link': toExport
        });
    }

    /**
     * Updates the volume at which background music audio files are played
     * @param {float} newVolume New volume value (between 0 and 1)
     */
    static changeBgmVolume(newVolume)
    {
        if (AudioPlayer.currentBgm)
            AudioPlayer.currentBgm.volume(newVolume);
    }

    /**
     * Updates the volume at which sound effect audio files are played
     * @param {float} newVolume New volume value (between 0 and 1)
     */
    static changeSfxVolume(newVolume)
    {
        //Foreach sfx, we update the volume
        for (let i = 0; i < AudioPlayer.currentSfxs.length; i++) {
            AudioPlayer.currentSfxs[i].volume(newVolume);
        }
    }

    /**
     * Stops all audio files currently playing
     */
    static stopAll()
    {
        Howler.stop();
    }

    /*
    -------------------------------------------
        BGM RELATED FUNCTIONS
    -------------------------------------------
    */

    /**
     * Plays a background music audio file
     * @param {string} bgm Key of the audio file to play from the AudioLibrary.bgm object
     */
    static playBgm(bgm) {
        //This function will play a background music (bgm) audio file
        if (AudioPlayer.currentBgm && AudioPlayer.currentBgm.libraryKey !== bgm) {
            AudioPlayer.stopBgm();
        }
        if (!AudioPlayer.currentBgm && AudioPlayer.library.bgm[bgm])
        {
            AudioPlayer.currentBgm = new Howl({
                src: AudioPlayer.library.bgm[bgm],
                loop: true,
                volume: useSettingsStore().volume.music/100,
                autoPlay: false
            });

            AudioPlayer.nextAudioId++;
            AudioPlayer.currentBgm.libraryKey = bgm;
            AudioPlayer.currentBgm.audioId = AudioPlayer.nextAudioId;

            AudioPlayer.currentBgm.play();
        }
    }

    /**
     * Stops the current background music audio file
     */
    static stopBgm() {
        //This function will stop the current background music (bgm) audio file
        if (AudioPlayer.currentBgm) {
            AudioPlayer.currentBgm.stop();
            AudioPlayer.currentBgm = null;
        }
    }

    /*
    -------------------------------------------
        SFX RELATED FUNCTIONS
    -------------------------------------------
    */
    /**
     * Plays a sound effect audio file
     * @param {string} sfx Key of the audio file to play from the AudioLibrary.sfx object
     * @return {int} Id of the audio file that was played
     */
    static playSfx(sfx) {
        //This function will play a background music (sfx) audio file
        if (AudioPlayer.library.sfx[sfx])
        {
            let newSfx = new Howl({
                src: AudioPlayer.library.sfx[sfx],
                loop: false,
                volume: useSettingsStore().volume.sound/100,
            });

            AudioPlayer.nextAudioId++;
            newSfx.libraryKey = sfx;
            newSfx.audioId = AudioPlayer.nextAudioId;

            newSfx.once('end', function() {
                for (let i = 0; i < AudioPlayer.currentSfxs.length; i++) {
                    //We remove the sfx from the list of currently playing sfxs
                    if (AudioPlayer.currentSfxs[i].audioId === newSfx.audioId) {
                        AudioPlayer.currentSfxs.splice(i, 1);
                        break;
                    }
                }
            });

            newSfx.play();
            AudioPlayer.currentSfxs.push(newSfx);

            return newSfx.audioId;
        }
    }

    /**
     * Stops all the sound effect audio files currently playing
     */
    static stopAllSfx()
    {
        for (let i = 0; i < AudioPlayer.currentSfxs.length; i++) {
            AudioPlayer.currentSfxs[i].stop();
        }
        AudioPlayer.currentSfxs = [];
    }
}