// import RoboNationCore
import RoboNationCore from '~/composables/RoboNationCore';

// return a color index for a given rarity
// 1 = 050
// 2 = 300
// 3 = 500

export default function useRarityIndex(rarity) {
    switch (rarity) {
        case 1:
            return '50';
        case 2:
            return '200';
        case 3:
            return '500';
        default:
            return '50';
    }
}

export function formatMoney(value = '0', lang = 'en') {
    // convert value to string
    value = value.toString();
    /*
     if lang === 'en'
     separe each 3 digits with a comma
     return '$' + value
     if value < 0, remove the - and add it at the beginning of the string
    */

    if (lang === 'en') {
        if (value < 0) {
            return '-$' + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('-', '');
        } else {
            return '$' + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }

    /*
        if lang === 'fr'
        separe each 3 digits with a space
        return value + '$'
        */
    if (lang === 'fr') {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + '$';
    }
}

/*
    add a method to sum price of item in a contract.
    Contract is a parameter of the function.
    It's an object of different items. Each item has a key and a value.
    Value is an object. In this object there is a key 'price' and a value.
    We want to sum all price of all items in the contract.


*/
export const sumContractPrice = (contract) => {
    // add a variable to store the total price, set it to 0
    let totalPrice = 0;
    // loop on each item in the contract
    for (const item in contract) {
        // add the price of the item to the total price x quantity (contract[item].amount)
        totalPrice += contract[item].price * contract[item].amount;
    }
    // return the total price
    return totalPrice;
};

/*
    add a method that output a stringl
    method has 2 parameters <material> (it's a object) and <id>(integer).
    The string as to be like this:
    <material.amount>x <span="text-primary-{useRarityIndex(<material.rarity>)}">$t('data.material.<id>'></span>
*/
export const formatMaterial = (material, id, group = 'material', used = false) => {
    // return a string
    // the <span> tag must be interpreted as html
    const rarity = material.rarity || material.category.rarity || 3;
    let quantity = `${material.amount}x`;

    if (used) {
        quantity = `${material.amount}/${material.owned}`;
    }

    return (
        quantity +
        ' <span class="text-primary-' +
        useRarityIndex(rarity) +
        '">' +
        useI18n().t(`data.${group}.${id}`) +
        '</span>'
    );
};

/*
    add a method that output a string
    method has 2 parameters <robot> (it's a object) and <id>(integer).
    The string as to be like this:
    <robot.amount>x <span="text-primary-{useRarityIndex(<robot.rarity>)}">$t('data.robot.<id>'></span>
*/
export const formatRobot = (robot, id) => {
    // return a string
    // the <span> tag must be interpreted as html
    return (
        robot.amount +
        'x <span class="text-primary-' +
        useRarityIndex(robot.rarity) +
        '">' +
        useI18n().t(`data.robot.${id}`) +
        '</span>'
    );
};

/*
    add a method that return information about a storage space.
    method has 1 parameter <playerId> (integer).
    it returns a string like this:
    <span>{totalUsedStorageSpace}/{availableSpace}</span>

    get totalUsedStorageSpace from RoboNationCore.instance.getWarehouseTotalUsedSpace(playerID);
    get availableSpace from RoboNationCore.instance.getWarehouseAvailableSpace(playerID);
*/
export const formatStorageSpace = (playerId, extraSpace = 0) => {
    // sum current used space and extra space, get current used space from RoboNationCore.instance.getWarehouseTotalUsedSpace(playerId)
    // put the result in a variable

    const usedSpace = RoboNationCore.instance.getWarehouseTotalUsedSpace(playerId) + extraSpace;

    // if usedSpace is greater than available space (use RoboNationCore.instance.getWarehouseTotalAvailableSpace(playerId))
    // set a variable to 'text-secondary-500'
    // else set a variable to ''
    const spaceClass =
        usedSpace > RoboNationCore.instance.getWarehouseTotalAvailableSpace(playerId) ? 'text-secondary-500' : '';

    // return a string
    // the <span> tag must be interpreted as html
    return (
        '<span class="' +
        spaceClass +
        '">' +
        usedSpace +
        '/' +
        RoboNationCore.instance.getWarehouseTotalAvailableSpace(playerId) +
        '</span>'
    );
};

/*

 add a method to
 convert number of days in parameter to  year, month, day.

*/
export const formatDate = (days) => {
    // convert days to years, months, days
    const years = Math.floor(days / 365) + 1;
    const months = Math.floor((days % 365) / 30) + 1;
    const daysLeft = Math.floor((days % 365) % 30) + 1;

    // return a string translated with i18n
    return useI18n().t('global.time', { year: years, month: months, day: daysLeft });
};

/*
    add a method to convert a number of seconds to a string like this:
    <hours>h<minutes>
    name is formatDuration
*/
export const formatDuration = (seconds) => {
    // convert seconds to hours and minutes
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    // in minutes, if minutes < 10, add a 0 before minutes
    const minutesString = minutes < 10 ? '0' + minutes : minutes;

    // return a string
    return hours + 'h' + minutesString;
};

/*
    add method formatStdDate with Timestamp in parameter (in milliseconds)
    return date in format YYYY-MM-DD if locale is en, DD-MM-YYYY if locale is fr
 */
export const formatStdDate = (timestamp) => {
    // convert timestamp to date
    const date = new Date(timestamp);

    // get year, month, day
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // if month < 10, add a 0 before month
    const monthString = month < 10 ? '0' + month : month;

    // if day < 10, add a 0 before day
    const dayString = day < 10 ? '0' + day : day;

    // return a string
    if (useI18n().locale.value === 'en') {
        return year + '-' + monthString + '-' + dayString;
    } else {
        return dayString + '-' + monthString + '-' + year;
    }
};

// add a method to convert seconds in minutes (XXminXXs)
export const formatMinute = (seconds) => {
    // convert seconds to minutes and seconds
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = Math.floor(seconds % 60);

    // if seconds < 10, add a 0 before seconds
    const secondsString = secondsLeft < 10 ? '0' + secondsLeft : secondsLeft;

    // return a string
    return minutes + 'm' + secondsString + 's';
};
