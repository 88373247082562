/*
 here we will create a modal store.
 We use Pinia to create a store.
 As defineStore is added in autoImports array of pinia in nuxt.config.js, don't import it !!!
 Let's go ... !
 */

// import RoboNationCore from '~/composables/RoboNationCore';
import RoboNationCore from '~/composables/RoboNationCore';

export const useModalStore = defineStore({
    /*
        We use modal to tell the user that something is happening.
        Modal has a :
        - title
        - message
        - variant (success, error, warning, info)
        - open (boolean)
        - 2 buttons that can be displayed or not and that can have a callback function
        - in some cases, we can have the component NumberPicker from ./components/NumberPicker.vue
    */
    id: 'modal',
    state: () => ({
        gameRunning: false,
        title: '',
        titleTheme: '',
        message: '',
        variant: '',
        open: false,
        contentWidth: 'max-w-[404px]',
        button1: {
            open: true,
            text: 'global.cancel',
            callback: null,
            theme: 'warehouse',
            extClass: '',
        },
        button2: {
            open: false,
            text: '',
            isClicked: false,
            theme: 'primary',
            extClass: '',
        },
        numberPicker: {
            open: false,
            min: 0,
            max: 0,
            value: 0,
            callback: null,
        },
        input: {
            open: false,
            value: '',
            placeholder: '',
            callback: null,
            errorMessage: '',
        },
        slider: {
            open: false,
        },
    }),

    getters: {
        getTitle() {
            return this.title;
        },
    },

    actions: {
        // open the modal
        // and pause the game with the pause function of RoboNationCore instance
        openModal(params) {
            this.title = params.title ?? this.title;
            this.titleTheme = params.titleTheme ?? this.titleTheme;
            this.message = params.message ?? this.message;
            this.variant = params.variant ?? this.variant;
            this.open = true;
            this.contentWidth = params.contentWidth ?? this.contentWidth;
            // if params.button1 is not defined, we set the default value defined in state
            this.button1 = params.button1 ? params.button1 : { open: false };
            this.button2 = params.button2 ? params.button2 : { open: false };
            this.numberPicker = params.numberPicker ? params.numberPicker : { open: false, value: 0 };
            this.input = params.input ? params.input : { open: false, value: '' };
            this.slider = params.slider ? params.slider : { open: false };

            // pause the game if the core is running
            // use RoboNationCore instance isRunning methods
            // if the game is running, we pause it and set the gameRunning value to true
            if (RoboNationCore.instance.isRunning()) {
                RoboNationCore.instance.pause();
                this.gameRunning = true;
            }
        },

        // close the modal
        // and resume the game with the resume function of RoboNationCore instance
        closeModal() {
            this.open = false;
            // reset all the values
            this.title = '';
            this.titleTheme = '';
            this.message = '';
            this.variant = '';
            this.contentWidth = 'max-w-[404px]';
            this.button1 = {
                open: true,
                text: 'global.cancel',
                callback: null,
            };
            this.button2 = {
                open: false,
                text: '',
                isClicked: false,
                theme: 'primary',
            };
            this.numberPicker = {
                open: false,
                min: 0,
                max: 0,
                value: 0,
                callback: null,
            };
            this.input = {
                open: false,
                errorMessage: '',
                value: '',
                placeholder: '',
                callback: null,
            };
            this.sider = {
                open: false,
            };

            // resume the game if the game was running
            if (this.gameRunning) {
                RoboNationCore.instance.resume();
                this.gameRunning = false;
            }
        },
    },
});
