<!--
    here the modal component to display important information to the user.
    It will invited the user to make choice between differetns options.

    add empty script tag with setup attribute
-->

<script setup>
// the component will be added in the layout component
// it will be open by multiple pages or components
// Modal is managed from the pinia store (see @/store/modal.js)
// (always end line with ';')
// start here:
import useGameStore from '~/store/game';
import useSettingsStore from '~/store/settings';
import { useModalStore } from '~/store/modal.js';
// import useAudio from composables
import { AudioPlayer } from '~/composables/useAudio';

const { t } = useI18n();

// get the modal store
const modalStore = useModalStore();

// close the modal
const closeModal = () => {
    modalStore.closeModal();
};

// get the modal state (open)
const modalState = computed(() => {
    return modalStore.open;
});

// get the modal title (title)
const modalTitle = computed(() => {
    return modalStore.title;
});
// get the modal title (title)
const modalTitleTheme = computed(() => {
    return modalStore.titleTheme;
});

// get the modal content width (modalContentwidth)
const modalContentwidth = computed(() => {
    return modalStore.contentWidth;
});

// get the modal message (message)
const modalMessage = computed(() => {
    return modalStore.message;
});

// get the modal button 1 (button1)
const modalButton1 = computed(() => {
    return modalStore.button1;
});

// get the modal button 2 (button2)
const modalButton2 = computed(() => {
    return modalStore.button2;
});

// get the modal NumberPicker (numberPicker)
const modalNumberPicker = computed(() => {
    return modalStore.numberPicker;
});

// get the modal input (input)
const modalInput = computed(() => {
    return modalStore.input;
});

// get the modal slider (slider)
const modalSlider = computed(() => {
    return modalStore.slider;
});

// get the modal variant (variant)
const modalVariant = computed(() => {
    return modalStore.variant;
});

// add a method to close the modal if the user click outside the modal (c-modal__card)
// (be careful c-modal__card is nested in c-modal__card-wrapper and has absolute sibling)
// or press the escape key
// eventlistener must be stopped when the component is destroyed
const closeModalOnOutsideClick = () => {
    // add event listener to the window
    window.addEventListener('click', (event) => {
        // if the user click outside the modal
        if (
            !event.target.classList.contains('c-modal__card') &&
            !event.target.classList.contains('c-modal__card-wrapper')
        ) {
            // close the modal
            // closeModal();
        }
    });

    // add event listener to the window
    window.addEventListener('keydown', (event) => {
        // if the user press the escape key
        if (event.key === 'Escape') {
            // close the modal
            closeModal();
        }
    });
};

// volume

const musicVolume = ref(useSettingsStore().volume.music);

const musicVolumeModel = computed({
    get() {
        return musicVolume.value;
    },
    set(value) {
        useSettingsStore().setMusicVolume(value);
        musicVolume.value = value;
        AudioPlayer.changeBgmVolume(value / 100);
    },
});

const soundVolume = ref(useSettingsStore().volume.sound);

const soundVolumeModel = computed({
    get() {
        return soundVolume.value;
    },
    set(value) {
        useSettingsStore().setSoundVolume(value);
        soundVolume.value = value;
        AudioPlayer.changeSfxVolume(value / 100);
    },
});

// add a watcher to the modal open state.
// if the modal is open, add the event listener to close the modal on outside click
// remove the watcher when the component is destroyed
const watchModalState = watch(
    () => modalStore.open,
    (value) => {
        if (value) {
            closeModalOnOutsideClick();
        } else {
            window.removeEventListener('click', closeModalOnOutsideClick);
            window.removeEventListener('keydown', closeModalOnOutsideClick);
        }
    }
);

// on unmounted, remove the event listener to close the modal on outside click
onUnmounted(() => {
    window.removeEventListener('click', closeModalOnOutsideClick);
    window.removeEventListener('keydown', closeModalOnOutsideClick);
    watchModalState();
});

// return the data to the component
// return {
//     modalState,
//     modalTitle,
//     modalMessage,
//     modalButton1,
//     modalButton2,
//     modalNumberPicker,
//     closeModal,
//     openModal,
// };
</script>

<!--
    here the template of the modal component
    it will be added in the layout component
    it will be open by multiple pages or components

    add class c-modal to the div
    Modal is an overlay, and all the elements will be in a card in the middle of the screen
    use tailwind classes.
    we will add the elements later

-->

<!-- open empty template tag -->

<template>
    <!-- add overlay -->
    <div
        v-if="modalState"
        class="c-modal theme-primary u-border absolute inset-0 z-50 flex items-center justify-center font-primary"
        :class="modalVariant !== '' ? 'c-modal--' + modalVariant : ''"
    >
        <div class="c-modal__background absolute inset-4"></div>
        <!-- add card with relative -->
        <div class="c-modal__card-wrapper u-gradient-border relative flex min-w-[640px] justify-center rounded-lg p-8">
            <!--
            add absolute background div, full width and height
            add a class c-modal__background
            add class u-gradient-border
        -->
            <div class="c-modal__card-background absolute inset-0 z-[-1]"></div>

            <div class="u-gradient-border absolute inset-0 z-10" />

            <div class="c-modal__card relative z-10 flex flex-col items-center text-primary-500">
                <!-- add title , if modalTitleTheme is not an empty string add class theme-<modalTitleTheme> -->
                <div
                    class="c-modal__title fotypeTynt-black py-2 text-center text-360 uppercase tracking-[.5rem]"
                    :class="modalTitleTheme !== '' ? 'text-' + modalTitleTheme + '-500' : ''"
                >
                    {{ t(modalTitle) }}
                </div>
                <div class="c-modal__content flex flex-col justify-center gap-4" :class="modalContentwidth">
                    <!-- add message -->
                    <div class="c-modal__message py-2 text-center text-160 leading-5" v-html="modalMessage"></div>
                    <!-- add input -->
                    <div v-if="modalInput.open">
                        <div class="u-gradient-border relative flex items-center justify-between">
                            <input
                                v-model="modalInput.value"
                                :placeholder="modalInput.placeholder"
                                maxlength="50"
                                type="text"
                                class="c-modal__input"
                            />
                            <GrIcon name="pencil" class="h-4 px-3" />
                        </div>
                        <!-- add a div for error message
                            display it if modalInput.errorMessage is not an empty string
                            use tailwind classes
                            use text-secondary-500 for the text color
                            and a small font size
                        -->
                        <div v-if="modalInput.errorMessage !== ''" class="mt-1 text-center text-140 text-secondary-500">
                            {{ modalInput.errorMessage }}
                        </div>
                    </div>
                    <!-- add number picker -->
                    <div v-if="modalNumberPicker.open" class="c-modal__number-picker py-2 text-center">
                        <NumberPicker
                            :max-value="modalNumberPicker.maxValue"
                            :current-value="modalNumberPicker.currentValue"
                            variant="longButton"
                            @update-picker-value="(value) => (modalNumberPicker.currentValue = value)"
                        />
                    </div>
                    <!-- add slider -->
                    <div v-if="modalSlider.open" class="c-modal__slider py-2 text-center">
                        <div class="flex items-end justify-between">
                            <span>
                                {{ $t('global.musicVolume') }}
                            </span>
                            <span v-text="musicVolume"></span>
                        </div>
                        <v-slider v-model="musicVolumeModel" :step="10"></v-slider>
                        <div class="flex items-end justify-between">
                            <span>
                                {{ $t('global.soundVolume') }}
                            </span>
                            <span v-text="soundVolume"></span>
                        </div>
                        <v-slider v-model="soundVolumeModel" :step="10"></v-slider>
                    </div>
                    <!-- add a separation line as in full.vue layout -->
                    <div class="h-[1px] w-full bg-primary-500"></div>
                    <!-- add buttons -->
                    <div class="c-modal__buttons flex justify-center gap-8 py-2">
                        <!-- add button 1 -->
                        <Button
                            v-if="modalButton1.open"
                            class="grow"
                            :class="modalButton1.extClass"
                            :theme="modalButton1.theme"
                            :label="t(modalButton1.text)"
                            @click="modalButton1.callback ?? closeModal()"
                        />
                        <!-- add button 2 -->
                        <Button
                            v-if="modalButton2.open"
                            class="grow"
                            :class="modalButton2.extClass"
                            :theme="modalButton2.theme"
                            :label="t(modalButton2.text)"
                            @click="modalButton2.isClicked = true"
                        />
                    </div>
                </div>
            </div>

            <!-- add div for glassmorphism effect -->
        </div>
    </div>
</template>

<!-- open style tag with lang=scss  and scoped attribute -->
<style lang="scss" scoped>
.c-modal {
    // add variables for each value, with --modal prefix
    // add a variable for background color , set it to black wit 75% opacity
    --modal-background-color: rgba(0, 0, 0, 0.75);
    --modal-border-radius: 3.2rem;
    --modal-card-background-color: rgba(2, 8, 16, 0.5);
    --modal-card-blur-effect: 20px;
    --modal-title-decoration: '//';

    //assign variables to the elements
    // add background color to the overlay
}

.c-modal--simple {
    --modal-title-decoration: '';
}

// for c-modal__title, i want to have '//' before and after the text, on the same line
// add a pseudo element ::before and ::after
// add content '//'
// the gap between the text and the pseudo element is the same as the gap between the text and the border
// use flexbox to align the pseudo element on the same line as the text even if the text is multiline

.c-modal__title {
    &::before,
    &::after {
        content: var(--modal-title-decoration);
    }
    display: flex;
    align-items: center;
    // add gap between the text and the pseudo element
    gap: 1rem;
}

.c-modal__background {
    background-color: var(--modal-background-color);
    border-radius: var(--screen-border-radius);
}

.c-modal__card-background {
    background-color: var(--modal-card-background-color);
    backdrop-filter: blur(var(--modal-card-blur-effect));
}

/*
    style for input:
    use @apply and tailwind classes
    - padding is 10 px (use variable global-tiny-spacing)
    - font-size is small (use custom tailwind class text-140)
    - text is align on left
    - width is full
    - color of the placeholder is the same as the text. use variable --theme-color-500
*/
.c-modal__input {
    @apply relative w-full text-left text-140 font-bold;
    padding: var(--global-tiny-spacing);
    color: var(--theme-color-500);

    &::placeholder {
        color: var(--theme-color-500);
        font-weight: 400;
    }

    // remove style on focus-visible
    &:focus-visible {
        outline: none;
    }
}

.c-modal__slider {
    // RGB conversion of --color-theme-500
    --v-theme-surface-variant: 0, 208, 255;

    min-width: 320px;
}
</style>
