/*
    here is the Pinia store for the game
    it contains the game state and the game logic
*/

// import roboNationCore from composables
import RoboNationCore from '~/composables/RoboNationCore';

export default defineStore('game', {
    state: () => ({
        // the game state
        // game ready
        ready: false,
        // game parameters, set it to null
        parameters: null,
        // object roboNationCore, set it to null
        roboNationCore: null,
        // current day
        currentDay: 0,
        // playerID, set it to null
        playerID: null,
        // isEventInProgress, set it to false
        isEvent: {
            inProgress: false,
            isUpdate: false,
        },
        // subchoiceInProgress, set it to false
        subchoiceInProgress: {
            0: false, // ROBOT
            1: false, // TECHNOLOGIE
            2: false, // STAFF
        },
        isUpdatePlayerMoney: false,
    }),

    actions: {
        // the game logic
        // set the game ready
        setReady() {
            this.ready = true;
        },

        // set the game parameters
        setParameters(parameters) {
            this.parameters = parameters;
            this.playerID = 1;
        },

        // init the game ,use RobotNationCore instance to init the game
        initGame() {
            // init the game
            RoboNationCore.instance.init();
            // store roboNationCore.instance in the store
            this.setRoboNationCore(RoboNationCore.instance);
        },

        // start the new game
        startNewGame() {
            // start the new game
            RoboNationCore.instance.startNewGame(this.parameters);
            // launch updateGame
            this.updateGame();
        },

        // add a method to update the game by checking roboNationCore.instance
        // use requestAnimationFrame to update the game
        updateGame() {
            // check if roboNationCore.instance is null
            if (this.roboNationCore === null) {
                // return
                return;
            }
            // update currentDay by calling roboNationCore.instance.getCurrentDay()
            this.currentDay = RoboNationCore.instance.getCurrentDate();
            // use requestAnimationFrame to update the game
            requestAnimationFrame(this.updateGame);
        },

        // set the roboNationCore
        setRoboNationCore(roboNationCore) {
            this.roboNationCore = roboNationCore;
        },
    },
});
