import { defineStore } from 'pinia'

const STORE_NAME = 'settings';

const getDefaultSettings = () => ({
    volume:{
        music: 60,
        sound: 100,
    }
});

const getSettings = () => {
    const settings = localStorage.getItem(STORE_NAME);
    return settings ? JSON.parse(settings) : getDefaultSettings()
}

const useSettingsStore = defineStore(STORE_NAME, {
    state: () => ({
        settings: getSettings()
    }),
    getters: {
        volume: (state) => state.settings.volume,
    },
    actions: {
        setMusicVolume(music) {
            this.settings.volume.music = music;
            localStorage.setItem(STORE_NAME, JSON.stringify(this.settings));
        },
        setSoundVolume(sound) {
            this.settings.volume.sound = sound;
            localStorage.setItem(STORE_NAME, JSON.stringify(this.settings));
        },
    },
  });

export default useSettingsStore;