<!--
    here is the code for the NumberPicker component:
    It allow user to select a number between a min and a max value.
    It is composed with three elements in a row:
    - a button to decrease the value
    - a text input to display the value
    - a button to increase the value


    component receive a prop name currentValue with a default value of 0

    start with the script tag with setup attribute
 -->
<script setup>
import { formatMoney } from '~/composables/useHelpers';

// props
const props = defineProps({
    currentValue: {
        type: Number,
        default: 0,
    },
    maxValue: {
        type: Number,
        default: 99,
    },
    minValue: {
        type: Number,
        default: 0,
    },
    step: {
        type: Number,
        default: 1,
    },
    formatMoney: {
        type: Boolean,
        default: false,
    },
    // add unit prop with a default value of ''
    unit: {
        type: String,
        default: '',
    },
    // add color theme prop with a default value of 'primary'
    colorTheme: {
        type: String,
        default: 'primary',
    },
    // add variant prop with a default value of ''
    variant: {
        type: String,
        default: '',
    },
});

// convert props to ref
const currentValue = ref(props.currentValue);

// add computed value processValue. If the currentValue is not a number, set it to 0. If value < 10. add 0 before the value
// return a Integer
const processValue = computed(() => {
    if (isNaN(currentValue.value)) {
        currentValue.value = props.minValue;
    }
    if (currentValue.value < 10) {
        return `0${currentValue.value}`;
    }
    if (props.formatMoney) {
        return formatMoney(currentValue.value);
    }
    return parseInt(currentValue.value);
});

// methods
// increase the value. convert the currentValue to a number, If the value is equal to the max value, do nothing
const increase = () => {
    const value = parseInt(currentValue.value);
    const newValue = value + props.step;
    if (newValue <= props.maxValue) {
        currentValue.value = newValue;
    } else {
        currentValue.value = props.maxValue;
    }
};

// decrease the value. If the value is equal to the min value, do nothing
const decrease = () => {
    const value = parseInt(currentValue.value);
    const newValue = value - props.step;
    if (newValue >= props.minValue) {
        currentValue.value = newValue;
    } else {
        currentValue.value = props.minValue;
    }
};

// add methods to handle long press on increase or decrease button
// if long press, increase or decrease the value each longPressDelay (set it to 500ms)
// after the first iteration, decrease the longPressDelay to 100ms
// if the value is equal to the max or min value, do nothing
// add a data for the timeout
let timeout = null;
let longPressDelay = 800;

// add a method to decrease the value on long press
const decreaseOnLongPress = () => {
    timeout = setTimeout(() => {
        decrease();
        longPressDelay = 100;
        decreaseOnLongPress();
    }, longPressDelay);
};

// add a method to increase the value on long press
const increaseOnLongPress = () => {
    timeout = setTimeout(() => {
        increase();
        longPressDelay = 100;
        increaseOnLongPress();
    }, longPressDelay);
};

// add a method to clear the timeout
const clearLongPress = () => {
    // reset the longPressDelay to 1200
    longPressDelay = 800;
    clearTimeout(timeout);
};

// add a method to check if the value enter in the input is a number and if it is between the min and max value
// if not, set the value to the min or max value
const checkValue = () => {
    if (isNaN(currentValue.value)) {
        currentValue.value = props.minValue;
    }
    if (currentValue.value > props.maxValue) {
        currentValue.value = props.maxValue;
    }
    if (currentValue.value < props.minValue) {
        currentValue.value = props.minValue;
    }
};

// add a watcher on currentValue to check the value
watch(
    () => props.currentValue,
    () => {
        currentValue.value = props.currentValue;
    }
);

// on mounted, check the value
onMounted(() => {
    checkValue();
});

// on each action, the component emit the new value
const emit = defineEmits(['updatePickerValue']);
const watchCurrentValue = watch(currentValue, () => {
    emit('updatePickerValue', currentValue.value);
});

// on beforeUnmount, remove the watcher
onBeforeUnmount(() => {
    watchCurrentValue();
});
</script>

<!-- template tag
    add class c-number-picker
    all buttons are ghost and small. Use button component and variant prop.
    use tailwind to style the text: font-primary, text-warehouse-500, text-2xl, font-bold, width-16, text-center
    use tailwind for layout: flex, items-center, justify-center with a gap of 8px
    button decrease: add a @click event to call the decrease method and add prop icon: 'smallMinus'
    add c-btn--disable if the value is equal to the min value
    button increase: add a @click event to call the increase method and add prop icon: 'smallPlus'
    add c-btn--disable if the value is equal to the max value
    start here:
-->
<template>
    <div class="c-number-picker gap flex items-center justify-center">
        <!-- handle long press with @mousedown @mouseup -->
        <Button
            variant="c-btn--ghost c-btn--small theme-primary"
            :class="variant === 'longButton' ? 'grow' : ''"
            :disabled="currentValue === minValue"
            icon="smallMinus"
            @click="decrease"
            @mousedown="decreaseOnLongPress"
            @mouseup="clearLongPress"
        />
        <!-- allow only number in input , set max to maxValue and min to minValue -->
        <input
            type="text"
            class="text-center font-primary text-2xl font-bold"
            :class="`text-${colorTheme}-500`"
            :style="{ width: `${String(processValue).length + 1}ch` }"
            :value="processValue"
            :max="maxValue"
            :min="minValue"
            @input="currentValue = $event.target.value"
            @blur="checkValue"
        />
        <!-- add unit prop -->
        <span v-if="unit !== ''" class="font-primary text-2xl font-bold" :class="`text-${colorTheme}-500`">{{
            unit
        }}</span>
        <Button
            variant="c-btn--ghost c-btn--small theme-primary"
            :class="variant === 'longButton' ? 'grow' : ''"
            :disabled="currentValue === maxValue"
            icon="smallPlus"
            @click="increase"
            @mousedown="increaseOnLongPress"
            @mouseup="clearLongPress"
        />
    </div>
</template>

<!-- add empty style tag , language is scss , scoped -->
<style lang="scss" scoped>
// reset input style when focused or selected
input:focus,
input:active {
    outline: none;
    border: none;
}
</style>
