import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBackground, LazyButton, LazyCard, LazyContractHandler, LazyCustomCheckbox, LazyCustomSelect, LazyDifficulty, LazyDropdown, LazyGameProgression, LazyGameSpeed, LazyGrIcon, LazyGradientProgress, LazyListBase, LazyListRow, LazyModal, LazyNavigation, LazyNotification, LazyNotifications, LazyNumberPicker, LazyPageFrame, LazyProgressBar, LazyRegion, LazyScreen, LazyModulesSelectItem, LazyModulesTwoCols, LazySvgAntenna, LazySvgArrowDown, LazySvgArrowUp, LazySvgAssembly, LazySvgAssemblyLine, LazySvgBagOfMoney, LazySvgBank, LazySvgBell, LazySvgCircleInfo, LazySvgClipboard, LazySvgCompanyInfo, LazySvgCompetitors, LazySvgDiamondExclamation, LazySvgDisk, LazySvgDispatch, LazySvgExit, LazySvgHorn, LazySvgHumanResources, LazySvgMarketStudy, LazySvgNationsMap, LazySvgPlus, LazySvgResearch, LazySvgSales, LazySvgShuffle, LazySvgSmallDisk, LazySvgSmallGear, LazySvgSmallHandshake, LazySvgSmallMinus, LazySvgSmallPlay, LazySvgSmallPlus, LazySvgSmallProduction, LazySvgSmallProtected, LazySvgSmallStop, LazySvgSmallTree, LazySvgSteal, LazySvgSuitcase, LazySvgTablet, LazySvgTrash, LazySvgTruck, LazySvgUser, LazySvgUserHelmetSafety, LazySvgWarehouse, LazySvgWarehouseStorage, LazySvgWrench, LazySvgDices, LazySvgSmallFlask, LazySvgSmallUnlock } from '#components'
const lazyGlobalComponents = [
  ["Background", LazyBackground],
["Button", LazyButton],
["Card", LazyCard],
["ContractHandler", LazyContractHandler],
["CustomCheckbox", LazyCustomCheckbox],
["CustomSelect", LazyCustomSelect],
["Difficulty", LazyDifficulty],
["Dropdown", LazyDropdown],
["GameProgression", LazyGameProgression],
["GameSpeed", LazyGameSpeed],
["GrIcon", LazyGrIcon],
["GradientProgress", LazyGradientProgress],
["ListBase", LazyListBase],
["ListRow", LazyListRow],
["Modal", LazyModal],
["Navigation", LazyNavigation],
["Notification", LazyNotification],
["Notifications", LazyNotifications],
["NumberPicker", LazyNumberPicker],
["PageFrame", LazyPageFrame],
["ProgressBar", LazyProgressBar],
["Region", LazyRegion],
["Screen", LazyScreen],
["ModulesSelectItem", LazyModulesSelectItem],
["ModulesTwoCols", LazyModulesTwoCols],
["SvgAntenna", LazySvgAntenna],
["SvgArrowDown", LazySvgArrowDown],
["SvgArrowUp", LazySvgArrowUp],
["SvgAssembly", LazySvgAssembly],
["SvgAssemblyLine", LazySvgAssemblyLine],
["SvgBagOfMoney", LazySvgBagOfMoney],
["SvgBank", LazySvgBank],
["SvgBell", LazySvgBell],
["SvgCircleInfo", LazySvgCircleInfo],
["SvgClipboard", LazySvgClipboard],
["SvgCompanyInfo", LazySvgCompanyInfo],
["SvgCompetitors", LazySvgCompetitors],
["SvgDiamondExclamation", LazySvgDiamondExclamation],
["SvgDisk", LazySvgDisk],
["SvgDispatch", LazySvgDispatch],
["SvgExit", LazySvgExit],
["SvgHorn", LazySvgHorn],
["SvgHumanResources", LazySvgHumanResources],
["SvgMarketStudy", LazySvgMarketStudy],
["SvgNationsMap", LazySvgNationsMap],
["SvgPlus", LazySvgPlus],
["SvgResearch", LazySvgResearch],
["SvgSales", LazySvgSales],
["SvgShuffle", LazySvgShuffle],
["SvgSmallDisk", LazySvgSmallDisk],
["SvgSmallGear", LazySvgSmallGear],
["SvgSmallHandshake", LazySvgSmallHandshake],
["SvgSmallMinus", LazySvgSmallMinus],
["SvgSmallPlay", LazySvgSmallPlay],
["SvgSmallPlus", LazySvgSmallPlus],
["SvgSmallProduction", LazySvgSmallProduction],
["SvgSmallProtected", LazySvgSmallProtected],
["SvgSmallStop", LazySvgSmallStop],
["SvgSmallTree", LazySvgSmallTree],
["SvgSteal", LazySvgSteal],
["SvgSuitcase", LazySvgSuitcase],
["SvgTablet", LazySvgTablet],
["SvgTrash", LazySvgTrash],
["SvgTruck", LazySvgTruck],
["SvgUser", LazySvgUser],
["SvgUserHelmetSafety", LazySvgUserHelmetSafety],
["SvgWarehouse", LazySvgWarehouse],
["SvgWarehouseStorage", LazySvgWarehouseStorage],
["SvgWrench", LazySvgWrench],
["SvgDices", LazySvgDices],
["SvgSmallFlask", LazySvgSmallFlask],
["SvgSmallUnlock", LazySvgSmallUnlock]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
